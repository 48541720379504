.mediaViewInfo {
    --web-view-name: menu;
    --web-view-id: menu;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: menu;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#menu {
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 55px;
    bottom: 5px;
    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: menu;
    --web-view-id: menu;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bottom_menu_mon_project_assign {
    position: relative;
    width: 100%;
    height: 45px;
    /* left: 5px; */
    top: 5px;
    overflow: visible;
    display: grid;
    grid-template-columns: 20vw 20vw 20vw 20vw 20vw;
    justify-items: center;
}
#bg_of_menu {
    fill: transparent;
    /* stroke: rgba(112,112,112,1);
    stroke-width: 1px; */
    /* stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto; */
}
.bg_of_menu {
    position: absolute;
    overflow: visible;
    width: 100vw ;
    height: 45px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#highlight_blue_icon_ {
    fill: rgba(162,202,255,1);
}
.highlight_blue_icon_ {
    position: relative;
    overflow: visible;
    width: calc(100% - 10px);
    height: 45px;
    left: 0px;
    text-align: center;
    top: 0px;
    border-radius: 45px;
}
#client_page_img_btn {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 298px;
    top: 8px;
    overflow: visible;
}
#project_page_icon {
    position: relative;
    width: 30px;
    height: 30px;
    top: 8px;
 
    text-align: center;
    overflow: visible;
}
#staff_page_icon {
    position: relative;
    width: 30px;
    height: 30px;
    top: 8px;
    overflow: visible;
    text-align: center;

}

#dashboardmob_img {
    position: relative;
    width: 30px;
    height: 30px;
    top: 8px;
    overflow: visible;
    text-align: center;
}
#timeline_btn_img {
    position: relative;
    width: 30px;
    height: 30px;
    top: 8px;
    overflow: visible;
    text-align: center;

}
#logout_icon_I_mob_new{
    position: relative;
    width: 30px;
    height: 30px;
    top: 8px;
    overflow: visible;
    text-align: center;
}