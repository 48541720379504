.mediaViewInfo {
    --web-view-name: side component – 2;
    --web-view-id: side_component__2;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
    --web-is-overlay: true;
}
:root {
    --web-view-ids: side_component__2;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#side_component__2 {
    position: absolute;
    width: 56vw;
    height: 100vh;
    /* display: flex;
    justify-content: center; */
    /* margin: 60px; */
    right: 0px;
    
    overflow: hidden;
    cursor: pointer;
    --web-view-name: side component – 2;
    --web-view-id: side_component__2;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
    --web-is-overlay: true;
}
#over_all_grp_right_compo_mob {
    position: absolute;
    /* top: 10%;
    left: 20%; */
    width: 100%;
    height: 100%;
    left: 9px;
    top: 84px;
    overflow: visible;
}
#cmt_bck_btn {
    position: absolute;
    width: 25px;
    height: 25px;
    /* left: 12px;
    top: 70px; */
    overflow: visible;
    z-index: 10;
}
#categories_overall_card_mob {
    position: absolute;
    width: 197px;
    height: 200px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#grpTask_page_mob {
    position: absolute;
    width: 197px;
    height: 200px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#bgTask_page_mob {
    fill: transparent;
}
.bgTask_page_mob {
    position: absolute;
    overflow: visible;
    width: 197px;
    height: 200px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 20px );
    -webkit-backdrop-filter:  blur( 20px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#categories_Task_page_mob {
    left: 68px;
    top: 24px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Icon_ionic-minus_mob_Task_page {
    fill: rgba(0,0,0,1);
}
.Icon_ionic-minus_mob_Task_page {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 164.5px;
    top: 24px;
    transform: matrix(1,0,0,1,0,0);
}
#Scroll_doc_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 197px;
    height: 144px;
    left: 0px;
    top: 56px;
    overflow: hidden;
    overflow-y: scroll;
}
#ocumentation_grp_cat_mob_egory {
    position: relative;
    width: 167px;
    height: 20px;
    left: 16px;
    /* top: 7px; */
    overflow: visible;
}
#add_minus_Task_page_mob {
    left: 0px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 90px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#minus_Task_pag_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 147px;
    top: 0px;
    overflow: visible;
}
#add_mob_bg_minus_Task_page {
    fill: rgba(232,239,246,1);
}
.add_mob_bg_minus_Task_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#Icon_mob_ionic-minus_Task_page {
    fill: rgba(255,55,55,1);
    stroke: rgba(255,55,55,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Icon_mob_ionic-minus_Task_page {
    overflow: visible;
    position: absolute;
    width: 11.193px;
    height: 2.359px;
    left: 5.217px;
    top: 9.634px;
    transform: matrix(1,0,0,1,0,0);
}
#edit_grp_Task_page_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 119px;
    top: 0px;
    overflow: visible;
}
#add_bg_mob_edit_grp {
    fill: rgba(232,239,246,1);
}
.add_bg_mob_edit_grp {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#pencil_mob_1_edit_grp {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#input_categ_mobories_grp {
    position: relative;
    width: 168px;
    height: 22px;
    left: 15px;
    top: 5px;
    overflow: visible;
    
}
#cancel_grp_Tas_mobk_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 148px;
    top: 1px;
    overflow: visible;
}
#add_bgcancel_mob {
    fill: rgba(232,239,246,1);
}
.add_bgcancel_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#Icon_ioniccancel_mob {
    fill: rgba(255,55,55,1);
}
.Icon_ioniccancel_mob {
    overflow: visible;
    position: absolute;
    width: 14.056px;
    height: 14.056px;
    transform: translate(-1089px, -93px) matrix(1,0,0,1,1091.9718,95.9718) rotate(45deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#save_grp_bdr_Task_mob_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 120px;
    top: 1px;
    overflow: visible;
}
#save_bdr_Ta_mob_sk_page {
    fill: rgba(232,239,246,1);
}
.save_bdr_Ta_mob_sk_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#diskette_2_mob_Task_page {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#edit_documentat_mobion {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.edit_documentat_mobion {
    position: absolute;
    overflow: visible;
    width: 107px;
    height: 22px;
    left: 0px;
    top: 0px;
    border-radius:  10px;
    padding-left: 10px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#Modules_over_all_gr_mobp_task_ {
    position: absolute;
    width: 197px;
    height: 200px;
    left: 0px;
    top: 210px;
    overflow: visible;
}
#module_mob {
    position: absolute;
    width: 197px;
    height: 200px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#bdr_module_mob_Task_page {
    fill: transparent;
}
.bdr_module_mob_Task_page {
    position: absolute;
    overflow: visible;
    width: 197px;
    height: 200px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 20px );
    -webkit-backdrop-filter:  blur( 20px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#add_btn_T_mobask_page {
    fill: rgba(0,0,0,1);
}
.add_btn_T_mobask_page {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 161.5px;
    top: 24px;
    transform: matrix(1,0,0,1,0,0);
}
#module_mob_lbl_Task_page {
    left: 74px;
    top: 24px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Scroll_mof_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 197px;
    height: 143px;
    left: 0px;
    top: 57px;
    overflow: hidden;
    overflow-y: scroll;
}
#documentation_grp_module_mob {
    position: relative;
    width: 167px;
    height: 20px;
    left: 16px;
    /* top: 6px; */
    overflow: visible;
} 
#documentation_lbl__mob {
    left: 0px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 90px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#minus_grp_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 147px;
    top: 0px;
    overflow: visible;
}
#bdr_minus_mob_Task_page {
    fill: rgba(232,239,246,1);
}
.bdr_minus_mob_Task_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#minus_p_mobath_ {
    fill: rgba(255,55,55,1);
    stroke: rgba(255,55,55,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.minus_p_mobath_ {
    overflow: visible;
    position: absolute;
    width: 11.193px;
    height: 2.359px;
    left: 5.217px;
    top: 9.634px;
    transform: matrix(1,0,0,1,0,0);
}
#edit_btn_grp_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 119px;
    top: 0px;
    overflow: visible;
}
#bdr_mob_edit_option {
    fill: rgba(232,239,246,1);
}
.bdr_mob_edit_option {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#pencil_mob_1_Task_page {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#input_grp_modules_mob {
    position: relative;
    width: 168px;
    height: 22px;
    left: 15px;
    top: 5px;
    overflow: visible;
} 
#cancel_btn_grp_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 148px;
    top: 1px;
    overflow: visible;
}
#add_bg_cli_mobent_page {
    fill: rgba(232,239,246,1);
}
.add_bg_cli_mobent_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#Icon_ionic-cleintpage_mob {
    fill: rgba(255,55,55,1);
}
.Icon_ionic-cleintpage_mob {
    overflow: visible;
    position: absolute;
    width: 14.056px;
    height: 14.056px;
    transform: translate(-1089px, -93px) matrix(1,0,0,1,1091.9718,95.9718) rotate(45deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#save_btn_grp__mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 120px;
    top: 1px;
    overflow: visible;
}
#bdr_save_btn__mob {
    fill: rgba(232,239,246,1);
}
.bdr_save_btn__mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#diskette_2_mob {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#text_box_modul_mobe_task_page {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.text_box_modul_mobe_task_page {
    position: absolute;
    overflow: visible;
    width: 107px;
    height: 22px;
    left: 0px;
    top: 0px;
    border-radius:  10px;
    padding-left: 10px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#Modules_over_all_grp_task_page_mob {
    position: absolute;
    width: 197px;
    height: 200px;
    left: 0px;
    top: 420px;
    overflow: visible;
}
#module__mob {
    position: absolute;
    width: 197px;
    height: 200px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#bdr_module_Task_page_mob {
    fill: transparent;
}
.bdr_module_Task_page_mob {
    position: absolute;
    overflow: visible;
    width: 197px;
    height: 200px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 20px );
    -webkit-backdrop-filter:  blur( 20px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#add_btn_Task_page_mob {
    fill: rgba(0,0,0,1);
}
.add_btn_Task_page_mob {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 161.5px;
    top: 24px;
    transform: matrix(1,0,0,1,0,0);
}
#module_lbl_Task_page_mob {
    left: 84px;
    top: 24px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Scroll_team_cards_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 197px;
    height: 143px;
    left: 0px;
    top: 57px;
    overflow: hidden;
    overflow-y: scroll;
}
#team_grp_module_mob {
    position: relative;
    width: 193px;
    height: 64px;
    left: 2px;
    top: 3px;
    overflow: visible;
    margin-top: 5px;
}
#team_bdr__mob {
    fill: rgba(255,255,255,1);
}
.team_bdr__mob {
    position: absolute;
    overflow: visible;
    width: 193px;
    height: 64px;
    left: 0px;
    top: 0px;
}
#documentation__moblbl_team_sta {
    left: 4px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#documentation_lbl_mob_role_tea {
    left: 5px;
    top: 24px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(4,4,4,1);
}
#documentation_lbl_d_mobate_tea {
    left: 5px;
    top: 40px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(4,4,4,1);
}