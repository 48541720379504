.mediaViewInfo {
    --web-view-name: pc login – 3;
    --web-view-id: pc_login__3;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pc_login__3;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pc_login__3 {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: pc login – 3;
    --web-view-id: pc_login__3;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#emile-perron-xrVDYZRGdw4-unspl {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#view {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 1095px;
    top: 364px;
    overflow: visible;
}
#Group_1 {
    position: absolute;
    width: 155px;
    height: 200px;
    left: 1211px;
    top: 568px;
    overflow: visible;
}
#mid_overall {
    position: absolute;
    width: 392px;
    height: 458px;
    left: 50%;
    top: 50%;
    overflow: visible;
    transform: translate(-50%, -50%);
}
#bg_dummy {
    fill: transparent;
}
.bg_dummy {
    position: absolute;
    overflow: visible;
    width: 392px;
    height: 413px;
    left: 0px;
    top: 45px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 20px );
    -webkit-backdrop-filter:  blur( 20px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#logo {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 151px;
    top: 0px;
    overflow: visible;
}
#Forgot_Password1 {
    left: 86px;
    top: 124px;
    position: absolute;
    overflow: visible;
    width: 221px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: rgba(226,27,27,1);
}
#btn {
    position: absolute;
    width: 305px;
    height: 45px;
    left: 44px;
    top: 358px;
    overflow: visible;
    border-radius: 5px;
}
#Rectangle_3_s {
    fill: url(#Rectangle_3_s);
}
.Rectangle_3_s {
    position: absolute;
    overflow: visible;
    width: 305px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#Continue {
    left: 115px;
    top: 12px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Berlin Sans FB;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#password1 {
    fill: rgba(255,255,255,1);
}
.password1 {
    position: absolute;
    overflow: visible;
    width: 305px;
    height: 46px;
    left: 44px;
    top: 259px;
    border-radius: 5px;
    background:  transparent;
    margin-bottom:  30px;
    border-bottom:  solid seagreen;
    padding: 5px;
}
#Icon_ionic-md-arrow-round-back {
    fill: rgba(0,0,0,1);
}
.Icon_ionic-md-arrow-round-back {
    overflow: visible;
    position: absolute;
    width: 18.74px;
    height: 17.678px;
    left: 291.074px;
    top: 308.33px;
    transform: matrix(1,0,0,1,0,0);
}
#Back {
    left: 314px;
    top: 307px;
    position: absolute;
    overflow: visible;
    width: 32px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Enter_the_email_address_associ {
    left: 44px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 327px;
    height: 60px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}