.mediaViewInfo {
    --web-view-name: examples;
    --web-view-id: examples;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: examples;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#examples {
    position: absolute;
    width: 50px;
    /* height: 34vh ; */
    right: 0px; 
    top: 7px;
    z-index: 10;
    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: examples;
    --web-view-id: examples;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#exmple_1_mob {
    position: relative;
    width: 33px;
    height: 33px;
    left: 15px;
    /* top: 10px; */
    overflow: visible;
    border-radius: 10px;
}
#example_2_mob {
    position: relative;
    width:33px;
    height: 33px;
    left: 15px;
    /* top: 60px; */
    overflow: visible;
    border-radius: 10px;

}
#example_3_mob {
    position: relative;
    width: 33px;
    height: 33px;
    left: 15px;
    /* top: 110px; */
    overflow: visible;
    border-radius: 10px;

}
#logout_icon_I_mob{
    position: relative;
    width: 33px;
    height: 33px;
    left: 15px;
    /* top: 160px; */
    overflow: visible;
    border-radius: 10px;
    cursor: pointer;
}
#reset_pass_mob{
    position: relative;
    width: 33px;
    height: 33px;
    left: 15px;
    /* top: 210px; */
    overflow: visible;
    border-radius: 10px;
    cursor: pointer;
}
#profile_pic_card_top_bar_mob {
    /* position: absolute; */
    width: 40px;
    height: 40px;
    left: 323px;
    top: 8px;
    overflow: hidden;
border-radius: 50%;
}
#top_nav_users_mob{
    position: relative;
    /* top: 5px; */
    right: 7px;
    z-index: 15;
    background-color:white;
    height: auto;
    display: grid;
    padding: 5px 0px 15px;
    /* margin-top: 4px; */
}