.mediaViewInfo {
    --web-view-name: User page mob;
    --web-view-id: User_page_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: User_page_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#User_page_mob {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: User page mob;
    --web-view-id: User_page_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_bg_design_userpage_mo {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#userpage_mob_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 90%;
    left: 0px;
    top: 63px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#card_1_user_page_mob {
    position: relative;
    width: 93vw;
    height: 105px;
    left: 12px;
    top: 8px;
    overflow: visible;
    margin-top: 7px;
}
#card_over_all_bg_userpage_mob {
    fill: transparent;
}
.card_over_all_bg_userpage_mob {
    position: absolute;
    overflow: visible;
    width: 93vw;
    height: 105px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#card_bg_design_userpage_mob {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 0px;
    top: 1.5px;
    overflow: visible;
}
/* #pfp_behind_circle_userpage_mob {
    fill: rgba(255,255,255,1);
} */
.pfp_behind_circle_userpage_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 104px;
    height: 104px;
    left: 7px;
    top: 10px;
}
#profile_pic_card_userpage_mob {
    position: absolute;
    width: 82px;
    height: 82px;
    left: 9px;
    top: 12px;
    overflow: hidden;
    border-radius: 50%;
}
#email_lbl_userpage_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 120px;
    top: 23px;
    overflow: visible;
}
#affanghanigmailcom_lbl_userpag {
    left: 147px;
    top: 25px;
    position: absolute;
    overflow: visible;
    width: 134px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#iphone-5_lbl_userpage_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 120px;
    top: 83px;
    overflow: visible;
}
#n_234567890_mobile_number_user {
    left: 147px;
    top: 85px;
    position: absolute;
    overflow: visible;
    width: 71px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#client_lbl_userpage_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 120px;
    top: 53px;
    overflow: visible;
}
#client_lbl_userpage_mob_c {
    left: 147px;
    top: 55px;
    position: absolute;
    overflow: visible;
    width: 34px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#manager_img_userpage_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 237px;
    top: 53px;
    overflow: visible;
}
#manager_lbl_userpage_mob {
    left: 262px;
    top: 55px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Active_lbl_userpage_mob {
    left: 271px;
    top: 85px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Mohammed_Affan_lbl_userpage_mo {
    left: 120px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 226px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Rectangle_7 {
    fill: rgba(255,255,255,1);
}
.Rectangle_7 {
    position: absolute;
    overflow: visible;
    width: 29px;
    height: 12px;
    left: 237px;
    top: 88px;
}