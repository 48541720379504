.mediaViewInfo {
    --web-view-name: client dashboard mob;
    --web-view-id: client_dashboard_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: client_dashboard_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#client_dashboard_mob {
    position: absolute;
    width: 100vw;
    height: 100vh;
/* z-index: 10; */
    /* background-color: rgba(255,255,255,1); */
    /* overflow-y: scroll; */
    overflow: hidden;
    --web-view-name: client dashboard mob;
    --web-view-id: client_dashboard_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_bg_design_dash_mob {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    background-size: cover;
    overflow: visible;
}
#assigned_staff_grp_card_dash_m {
    position: relative;
    width: 285px;
    height: 107px;
    /* left: 7px; */
    top: 545px;
    display: inline-block;
    overflow: visible;
    margin-right: 10px;
}
/* #project_left_bdr_over_all_mob {
    fill: rgba(255,255,255,1);
}
.project_left_bdr_over_all_mob {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 349px;
    height: 135px;
    left: 0px;
    top: 0px;
} */
#card_overall_bdr_assign_mob {
    fill: transparent;
}
.card_overall_bdr_assign_mob {
    position: absolute;
    overflow: visible;
    width: 285px;
    height: 84px;
    left: 28px;
    top: 33px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#overall_pfp_bdr_assign_carddas {
    position: absolute;
    width: 86px;
    height: 84px;
    left: 28px;
    top: 33px;
    overflow: visible;
}
/* #pfp_bdr_assign_carddash_mob {
    fill: rgba(255,255,255,1);
} */
.pfp_bdr_assign_carddash_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 92px;
    height: 92px;
    left: 34px;
    top: 38px;
}
#profile_pic_img_assign_carddas {
    position: absolute;
    width: 65px;
    height: 65px;
    left: 39px;
    top: 43px;
    overflow: hidden;
    border-radius: 50%;
}
#Mohammed_Affan_lbl_assign_card_mob {
    left: 129px;
    top: 36px;
    position: absolute;
    overflow: visible;
    width: 175px;
    height: 17px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#start_end_bdr_assigndash_mob {
    fill: rgba(119,238,170,1);
}
.start_end_bdr_assigndash_mob {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 23px;
    left: 129px;
    top: 59px;
}
#start_end_date_assign_carddash {
    left: 137px;
    top: 62px;
    position: absolute;
    overflow: visible;
    width: 160px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(255,255,255,1);
}
#orange_bdr_assigned_mob {
    fill: rgba(255,204,0,1);
}
.orange_bdr_assigned_mob {
    position: absolute;
    overflow: visible;
    width: 20px;
    height: 20px;
    left: 130px;
    top: 92px;
}
#blue_bdr_assigneddash_mob {
    fill: rgba(51,153,255,1);
}
.blue_bdr_assigneddash_mob {
    position: absolute;
    overflow: visible;
    width: 20px;
    height: 20px;
    left: 169px;
    top: 92px;
}
#yellow_bdr_assigneddash_mob {
    fill: rgba(255,153,0,1);
}
.yellow_bdr_assigneddash_mob {
    position: absolute;
    overflow: visible;
    width: 20px;
    height: 20px;
    left: 205px;
    top: 92px;
}
#green_bdr_assigned_mob {
    fill: rgba(0,204,102,1);
}
.green_bdr_assigned_mob {
    position: absolute;
    overflow: visible;
    width: 20px;
    height: 20px;
    left: 244px;
    top: 92px;
}
#red_bdr_assigneddash_mob {
    fill: rgba(255,0,0,1);
}
.red_bdr_assigneddash_mob {
    position: absolute;
    overflow: visible;
    width: 20px;
    height: 20px;
    left: 283px;
    top: 92px;
}
#yellow_11_assigneddash_mob {
    left: 132px;
    top: 92px;
    position: absolute;
    overflow: visible;
    width: 17px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#blue_11_assigned_dash_mob {
    left: 171px;
    top: 92px;
    position: absolute;
    overflow: visible;
    width: 17px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#orange_11_assigned_dash_mob {
    left: 207px;
    top: 92px;
    position: absolute;
    overflow: visible;
    width: 17px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#green_11_assigned_dash_mob {
    left: 246px;
    top: 92px;
    position: absolute;
    overflow: visible;
    width: 17px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#red_11_assigned_dash_mob {
    left: 285px;
    top: 92px;
    position: absolute;
    overflow: visible;
    width: 17px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#Projects_lbl_client_dash_mob {
    right: 0px;
    top: 520px;
    position: absolute;
    overflow: visible;
    width: 107px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#project_add_bdr_dash_mob {
    fill: rgba(232,239,246,1);
}
.project_add_bdr_dash_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 53px;
    top: 1px;
}
#project_add_path_dash_mob {
    fill: rgba(0,0,0,1);
}
.project_add_path_dash_mob {
    overflow: visible;
    position: absolute;
    width: 15px;
    height: 15px;
    left: 56px;
    top: 4px;
    transform: matrix(1,0,0,1,0,0);
}
#bar_chart_grp_client_dash_mob {
    position: absolute;
    /* width: calc(100% - 200px); */
width: 100%;
    height: 168px;
    left: 5px;
    top: 135px;
    display: inline-flex;
    overflow-x: scroll;
    /* width: calc(100% - 510px); */
    /* height: 291px; */
    /* left: 504px; */
   
    /* right: 50px; */
    /* top: 84px; */
    /* bottom: 250px; */
}
#bar_chart_behind_bdr_dash_mob {
    fill: rgba(255,255,255,1);
}
.bar_chart_behind_bdr_dash_mob {
    position: absolute;
    overflow: visible;
    width: 364.437px;
    height: 165px;
    left: 0px;
    top: 0px;
}

#add_task_bar_graph_dash_mob {
    fill: rgba(232,239,246,1);
}
.add_task_bar_graph_dash_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 36px;
    height: 36px;
    /* left: 330.196px; */
    right: 10px;
    top: 0px;
}
#task_add_chat_bar_dash_mob {
    fill: rgba(0,0,0,1);
}
.task_add_chat_bar_dash_mob {
    overflow: visible;
    position: absolute;
    width: 14px;
    height: 14px;
    /* left: 332.196px; */
    right: 30px;
    top: 2px;
    transform: matrix(1,0,0,1,0,0);
}
#Tasks_lbl_chart_bar_dash_mob {
    /* left: 280px; */
    right: 70px;
    top: -1.5px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#pie_chart_grp_dash_mob {
    position: absolute;
    width: 134px;
    height: 150px;
    left: 17px;
    top: 365px;
    overflow: visible;
}
#pie_chart_client_dash_mob {
    position: absolute;
    width: 150px;
    height: 150px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Total_Tasks_-_100_dash_mob {
    left: 180px;
    top: 474px;
    position: absolute;
    overflow: visible;
    width: 125px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#scroll_project_name_dash_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 67px;
    left: 55px;
    top: 61px;
    overflow-x: scroll;
    overflow-y: hidden;
}
#project_grp_card_1 {
    position: relative;
    margin-left: 10px;
    padding: 32px;
    width: 222px;
    height: 58px;
    left: -5px;
    top: 4px;
    overflow: visible;
    display: inline-flex;

}
#card_bdr_client_dash_mob {
    fill: rgba(255,255,255,1);
}
.card_bdr_client_dash_mob {
    filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 228px;
    height: 66px;
    left: 0px;
    top: 0px;
}
#profile_pfp_img_client_dash_mo {
    position: absolute;
    width: 44px;
    height: 44px;
    left: 7px;
    top: 7px;
    overflow: hidden;
    box-shadow:   box-shadow;
    background:  #e0e0e0;
    border-radius: 50%;
}
#Project_Name_client_dash_mob {
    left: 56px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 162px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Start_time_projpage_client_das {
    left: 56px;
    top: 37px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(4,4,4,1);
}
#date_proj_page_clientdash_mob {
    left: 140px;
    top: 37px;
    position: absolute;
    overflow: visible;
    width: 74px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(4,4,4,1);
}
#project_add_bdr__dash_mob {
    fill: rgba(232,239,246,1);
}
.project_add_bdr__dash_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 50px;
    height: 76px;
    left: 18px;
    top: 65px;
}
#project_add_path_dash_mob_d {
    fill: rgba(0,0,0,1);
}
.project_add_path_dash_mob_d {
    overflow: visible;
    position: absolute;
    width: 26.294px;
    height: 26.294px;
    left: 20.853px;
    top: 80.854px;
    transform: matrix(1,0,0,1,0,0);
}
/* #Unassigned_lbl_client_dash_mob {
    left: 170.115px;
    top: 353px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#unassigned_grey_dash_mob {
    fill: rgba(153,153,153,1);
}
.unassigned_grey_dash_mob {
    position: absolute;
    overflow: visible;
    width: 10.562px;
    height: 10.563px;
    left: 155.615px;
    top: 356px;
}
#Assigned_lbl_client_dash_mob {
    left: 257.661px;
    top: 353px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#yellow_assigned_client_dash_mo {
    fill: rgba(255,204,0,1);
}
.yellow_assigned_client_dash_mo {
    position: absolute;
    overflow: visible;
    width: 10.562px;
    height: 10.563px;
    left: 241.775px;
    top: 356px;
}
#Work_in_progress_client_dash_m {
    left: 184.327px;
    top: 382px;
    position: absolute;
    overflow: visible;
    width: 91px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#blue_work_in_progress_dash_mob {
    fill: rgba(51,153,255,1);
}
.blue_work_in_progress_dash_mob {
    position: absolute;
    overflow: visible;
    width: 10.562px;
    height: 10.563px;
    left: 169px;
    top: 385px;
}
#Completed_lbl_client_dash_mob {
    left: 300.491px;
    top: 382px;
    position: absolute;
    overflow: visible;
    width: 59px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#completed_greeen_dash_mob {
    fill: rgba(0,204,102,1);
}
.completed_greeen_dash_mob {
    position: absolute;
    overflow: visible;
    width: 10.562px;
    height: 10.563px;
    left: 284.83px;
    top: 385px;
}
#Cancelled_lbl_client_dash_mob {
    left: 197.661px;
    top: 409px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#red_cancelled_client_dash_mob {
    fill: rgba(255,0,0,1);
}
.red_cancelled_client_dash_mob {
    position: absolute;
    overflow: visible;
    width: 10.562px;
    height: 10.563px;
    left: 182px;
    top: 412px;
}
#On_Hold_lbl_client_dash_mob {
    left: 275.664px;
    top: 409px;
    position: absolute;
    overflow: visible;
    width: 46px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#orange_on_hold_dash_mob {
    fill: rgba(255,153,0,1);
}
.orange_on_hold_dash_mob {
    position: absolute;
    overflow: visible;
    width: 10.562px;
    height: 10.563px;
    left: 259.591px;
    top: 413px;
} */
#eye_grp_dash_mob {
    position: absolute;
    width: 100%;
    height: 20px;
    left: 15px;
    top: 312px;
    overflow: visible;
}
#eye_unassigned_dash_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#eye_assigned_dash_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 49px;
    top: 0px;
    overflow: visible;
}
#eye_work_in_progress_dash_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 98px;
    top: 0px;
    overflow: visible;
}
#eye_on_hold_dash_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 147px;
    top: 0px;
    overflow: visible;
}
#eye_completed_dash_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 197px;
    top: 0px;
    overflow: visible;
}
#eye_cancelled_dash_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 246px;
    top: 0px;
    overflow: visible;
}
#dashboard_overall_scroll{
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 90%;
    /* left: 55px; */
    display: block;
    text-align: center;
    top: 7px;
    overflow-y: scroll;
    overflow-x: hidden;
}
#dashboard_overall_scroll::-webkit-scrollbar{
    display: none;
}
#unassigned_grp_mob {
    position: absolute;
    width: 75.5px;
    height: 16px;
    left: 155.614px;
    top: 353px;
    overflow: visible;
}
#Unassigned_lbl_client_dash_mob {
    left: 14.5px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#unassigned_grey_dash_mob {
    fill: rgba(153,153,153,1);
}
.unassigned_grey_dash_mob {
    position: absolute;
    overflow: visible;
    width: 10.563px;
    height: 10.563px;
    left: 0px;
    top: 3px;
}
#assigned_grp_mob {
    position: absolute;
    width: 63.886px;
    height: 16px;
    left: 261px;
    top: 353px;
    overflow: visible;
}
#Assigned_lbl_client_dash_mob {
    left: 15.886px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#yellow_assigned_client_dash_mo {
    fill: rgba(255,204,0,1);
}
.yellow_assigned_client_dash_mo {
    position: absolute;
    overflow: visible;
    width: 10.563px;
    height: 10.563px;
    left: 0px;
    top: 3px;
}
#WIP_grp_mob {
    position: absolute;
    width: 105.327px;
    height: 16px;
    left: 178.451px;
    top: 410px;
    overflow: visible;
}
#Work_in_progress_client_dash_m {
    left: 15.327px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 91px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#blue_work_in_progress_dash_mob {
    fill: rgba(51,153,255,1);
}
.blue_work_in_progress_dash_mob {
    position: absolute;
    overflow: visible;
    width: 10.563px;
    height: 10.563px;
    left: 0px;
    top: 3px;
}
#completed_grp_mob {
    position: absolute;
    width: 73.661px;
    height: 16px;
    left: 261px;
    top: 380.932px;
    overflow: visible;
}
#Completed_lbl_client_dash_mob {
    left: 15.661px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 59px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#completed_greeen_dash_mob {
    fill: rgba(0,204,102,1);
}
.completed_greeen_dash_mob {
    position: absolute;
    overflow: visible;
    width: 10.563px;
    height: 10.563px;
    left: 0px;
    top: 3px;
}
#cancelled_grp_mob {
    position: absolute;
    width: 66.661px;
    height: 16px;
    left: 164.454px;
    top: 381px;
    overflow: visible;
}
#Cancelled_lbl_client_dash_mob {
    left: 15.661px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#red_cancelled_client_dash_mob {
    fill: rgba(255,0,0,1);
}
.red_cancelled_client_dash_mob {
    position: absolute;
    overflow: visible;
    width: 10.563px;
    height: 10.563px;
    left: 0px;
    top: 3px;
}
#on_hold_grp_mob {
    position: absolute;
    width: 61.07px;
    height: 16px;
    left: 193.364px;
    top: 438px;
    overflow: visible;
}
#On_Hold_lbl_client_dash_mob {
    left: 16.07px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 46px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#orange_on_hold_dash_mob {
    fill: rgba(255,153,0,1);
}
.orange_on_hold_dash_mob {
    position: absolute;
    overflow: visible;
    width: 10.563px;
    height: 10.563px;
    left: 0px;
    top: 3px;
}