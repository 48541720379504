.mediaViewInfo {
    --web-view-name: client page – 1;
    --web-view-id: client_page__1;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: client_page__1;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#client_page__1 {
    position: absolute;
    width: 100vw;
    height: 100vh;
    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: client page – 1;
    --web-view-id: client_page__1;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_bg_page_client_pc_new {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#client_pc_new_page_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100vw - 175px);
    height: 454px;
    left: 172px;
    top: 86px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#user_card_client_pc_new {
    position: relative;
    width: 174px;
    height: 200px;
    left: 20px;
    top: 10px;
    margin: 10px;
    overflow: visible;
}
#card_over_all_bgclient_pc_new {
    fill: transparent;
}
.card_over_all_bgclient_pc_new {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 200px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#card_bg_design_client_pc_new {
    position: absolute;
    width: 174px;
    height: 49px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#pfp_grpclient_pc_new {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 64px;
    top: 25px;
    overflow: visible;
}
/* #pfp_behind_circle_client_pc_ne {
    fill: rgba(119,238,170,1);
} */
.pfp_behind_circle_client_pc_ne {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 63px;
    left: 0px;
    top: 0px;
}
#profile_pic_cardclient_pc_new {
    position: absolute;
    width: 41px;
    height: 41px;
    left: 2px;
    top: 2px;
    overflow: hidden;
    border-radius: 50%;
}
#exp_client_pc_new {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 131px;
    overflow: visible;
}
#yr_client_pc_new {
    left: 37px;
    top: 133px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#iphone-client_pc_new {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 162px;
    overflow: visible;
}
#client_pc_new_mobile_number {
    left: 37px;
    top: 164px;
    position: absolute;
    overflow: visible;
    width: 71px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#manager_lbl_client_pc_new {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 101px;
    overflow: visible;
}
#Admin_lbl_client_pc_new {
    left: 37px;
    top: 103px;
    position: absolute;
    overflow: visible;
    width: 90px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Mohammed_Affan_lblclient_pc_ne {
    left: 68px;
    top: 74px;
    position: absolute;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}