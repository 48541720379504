#Column_Chart_nubers_grp {
    position: relative;
    width: 30px;
    margin: 10px;
    height: 231.021px;
    /* left: 436px; */
    /* top: 76px; */
    display: inline-flex;
    overflow: visible;
margin-right: 60px;
}
#task_1_box {
    fill: rgba(186,106,255,1);
}
.task_1_box {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 200px;
    /* left: 0px; */
    /* top: 17.416px; */
    bottom: 0px;
    rotate: 180deg;

}
#Task_Name1_1 {
    transform: translate(-2px, 60px) matrix(1,0,0,1,-87.5,57.541) rotate(90deg);
    transform-origin: center;
    /* left: 0px; */
    bottom: 0px;
    top: 0px;
    position: absolute;
    overflow: hidden;
    width: 205px;
    height: 17px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(255,255,255,1);
}
/* #blue_1_client_dashabord {
    fill: rgba(51,153,255,1);
} */
.blue_1_client_dashabord {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 200px;
    left: 25px;
    /* top: 17.416px; */
    /* bottom: 0px; */
    rotate: 180deg;
}
#n__blue_client_dashabord {
    left: 32px;
    /* top: 0px; */
    position: absolute;
    overflow: visible;
    width: 8px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#n__purple_1_client_dashabord {
    left: 7px;
    /* top: 0px; */
    position: absolute;
    overflow: visible;
    width: 8px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}