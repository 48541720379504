.mediaViewInfo {
    --web-view-name: mob login;
    --web-view-id: mob_login;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: mob_login;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#mob_login {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: mob login;
    --web-view-id: mob_login;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#emile-perr_mob_login_proj {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#mid_overall_mob_login_proj {
    position: absolute;
    width: 321px;
    height: 436px;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%);
    overflow: visible;
}
#bg_dummy_mob {
    fill: transparent;
}
.bg_dummy_mob {
    position: absolute;
    overflow: visible;
    width: 321px;
    height: 436px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.25 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 20px );
    -webkit-backdrop-filter:  blur( 20px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#logo_img_mob_login_proj {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 114px;
    top: 11px;
    overflow: visible;
}
#Project_Assignments_mob_login_ {
    left: 25px;
    top: 111px;
    position: absolute;
    overflow: visible;
    width: 269px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: rgba(226,27,27,1);
}
#btn_grp_mob_login_proj {
    position: absolute;
    width: 288px;
    height: 45px;
    left: 15px;
    top: 335px;
    overflow: visible;
    border-radius: 5px;
}
#sign_in_bdr_mob_login_proj_da {
    fill: url(#sign_in_bdr_mob_login_proj_da);
}
.sign_in_bdr_mob_login_proj_da {
    position: absolute;
    overflow: visible;
    width: 288px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#Sign_in_mob_login_proj {
    left: 117px;
    top: 12px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Berlin Sans FB;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#username_mob_login_proj {
    fill: rgba(254,254,254,1);
}
.username_mob_login_proj {
    position: absolute;
    overflow: visible;
    width: 288px;
    height: 46px;
    left: 15px;
    top: 175px;
    border-radius: 5px;
    border: solid 2px;
    padding: 5px;
}
#password_mob_login_proj {
    fill: rgba(255,255,255,1);
}
.password_mob_login_proj {
    position: absolute;
    overflow: visible;
    width: 288px;
    height: 46px;
    left: 15px;
    top: 235px;
    border-radius: 5px;
    border: solid 2px;
    padding: 5px;
}
#Forgot_Password_mob_login_proj {
    left: 185px;
    top: 283px;
    position: absolute;
    overflow: visible;
    width: 111px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,71,71,1);
}
