.mediaViewInfo {
    --web-view-name: pup up addproject;
    --web-view-id: pup_up_addproject;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pup_up_addproject;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pup_up_addproject {
    position: absolute;
    width: 442px;
    height: 862px;
    /* top: 50%; */
    left: 50%;
    transform: translate(-50%);
    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: pup up addproject;
    --web-view-id: pup_up_addproject;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_project_page_1 {
    fill: rgba(255,255,255,1);
}
.over_all_project_page_1 {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 379px;
    height: 790px;
    left: 39px;
    top: 28px;
}
#top_bg_project_page_1_ {
    fill: url(#top_bg_project_page_1_);
}
.top_bg_project_page_1_ {
    position: absolute;
    overflow: visible;
    width: 361px;
    height: 94px;
    left: 39px;
    top: 28px;
}
#active_pfp_add_project_pfp {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 170px;
    top: 38px;
    overflow: visible;
}
/* #pfp_behind_circle_bg3_project {
    fill: rgba(255,255,255,1);
} */
.pfp_behind_circle_bg3_project {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 118px;
    height: 118px;
    left: 0px;
    top: 0px;
}
#profile_pfp_img3_project {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 10px;
    top: 9.004px;
    overflow: hidden;
    box-shadow:   box-shadow;
    background:  #e0e0e0;
    border-radius: 50%;
}
#project_name_grp_prof {
    position: absolute;
    width: 321.329px;
    height: 62px;
    left: 59px;
    top: 186.504px;
    overflow: visible;
}
#project_name__lbl_pfp {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#project_name__input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.project_name__input_pfp {
    position: absolute;
    overflow: visible;
    width: 321.329px;
    height: 40px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#project_name__grp_prof {
    position: absolute;
    width: 321.329px;
    height: 63px;
    left: 59px;
    top: 258.496px;
    overflow: visible;
}
#project_name__lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 103px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#project_name__input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.project_name__input_pfp {
    position: absolute;
    overflow: visible;
    width: 321.329px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#project_CPN__skill_grp {
    position: absolute;
    width: 321.579px;
    height: 63px;
    left: 59px;
    top: 507.504px;
    overflow: visible;
}
#Project_CPN_skill_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 164px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#project__skill_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.project__skill_input_pfp {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#project_Info_exp_grp {
    position: absolute;
    width: 321.8px;
    height: 63px;
    left: 59px;
    top: 331.496px;
    overflow: visible;
}
#project__exp_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 146px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#project__exp_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.project__exp_input_pfp {
    position: absolute;
    overflow: visible;
    width: 321.8px;
    height: 70px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#CPD_project_grp {
    position: absolute;
    width: 321.579px;
    height: 63px;
    left: 59px;
    top: 580.504px;
    overflow: visible;
}
#project_pup_op_staff_lbl {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 209px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#project__pup_op_staff_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.project__pup_op_staff_input {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#project_active_grp_ {
    position: absolute;
    width: 97.572px;
    height: 27px;
    left: 171px;
    top: 146.004px;
    overflow: visible;
}
#active_lbl_project {
    left: 43.572px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
/* #active_chkbx {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.active_chkbx {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 20px;
    left: 0px;
    top: 4px;
} */
#Add_pup_op_project__btn_grp:hover {
    left: 168px;
    top: 746px;
}
#Add_pup_op_project__btn_grp {
    position: absolute;
    width: 123px;
    height: 41px;
    left: 162px;
    top: 739px;
    overflow: visible;
    border-radius: 20px;
    transition: all .3s ease-out;
}
#addd_bdr_btn_project_ {
    fill: rgba(192,255,219,1);
}
.addd_bdr_btn_project_ {
    position: absolute;
    overflow: visible;
    width: 123px;
    height: 41px;
    left: 0px;
    top: 0px;
}
#Add_pup_opproject__lbl {
    left: 43.914px;
    top: 7.28px;
    position: absolute;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#project__mob_grp {
    position: absolute;
    width: 150.61px;
    height: 63px;
    left: 59px;
    top: 434.504px;
    overflow: visible;
}
#project_mob_lbl_pup_op {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#project__mob_pup_op_staff {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.project__mob_pup_op_staff {
    position: absolute;
    overflow: visible;
    width: 150.61px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#close_pup_op_project_ {
    position: absolute;
    width: 25px;
    cursor: pointer;
    height: 25px;
    left: 365px;
    top: 38px;
    overflow: visible;
}
#end_date_grp {
    position: absolute;
    width: 150.61px;
    height: 63px;
    left: 230px;
    top: 434.504px;
    overflow: visible;
}
#project__mob_lbl_pup_op_staff {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#project_mob_pup_op_staff {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.project_mob_pup_op_staff {
    position: absolute;
    overflow: visible;
    width: 150.61px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#CPM_project_grp {
    position: absolute;
    width: 321.579px;
    height: 63px;
    left: 59px;
    top: 653.504px;
    overflow: visible;
}
#remark_pup_op_project__lbl {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 171px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#remark_pup_opproject__input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.remark_pup_opproject__input {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
.switch_project{
    left: -26px;
    top: 3px;
}