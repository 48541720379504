.mediaViewInfo {
    --web-view-name: pup up task page;
    --web-view-id: pup_up_task_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pup_up_task_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pup_up_task_page {
    position: absolute;
    width: 442px;
    height: 897px;
   /* left: 50%;
   top: 60%;
   transform: translate(-50%, -50%); */
   left: 50%;
    transform: translate(-50%);
   text-align: center;
    border: none;
    overflow: hidden;
    --web-view-name: pup up task page;
    --web-view-id: pup_up_task_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_bg_add_task_pop_up {
    fill: rgba(255,255,255,1);
}
.over_all_bg_add_task_pop_up {
    /* filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161)); */
    position: absolute;
    overflow: visible;
    width: 379px;
    height: 841px;
    left: 39px;
    top: 42px;
}
#top_bg_add_task_pop_up_bu {
    fill: url(#top_bg_add_task_pop_up_bu);
}
.top_bg_add_task_pop_up_bu {
    position: absolute;
    overflow: visible;
    width: 361px;
    height: 94px;
    left: 39px;
    top: 28px;
}
#add_add_task_pop_up:hover {
    left: 168px;
    top: 799px;
}
#add_add_task_pop_up {
    position: absolute;
    width: 123px;
    height: 41px;
    left: 164px;
    top: 792px;
    overflow: visible;
    border-radius: 20px;
    transition: all .3s ease-out;
}
#addd_bdr_btn_add_task_pop_up {
    fill: rgba(192,255,219,1);
}
.addd_bdr_btn_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 123px;
    height: 41px;
    left: 0px;
    top: 0px;
}
#Add_add_task_pop_up_lbl {
    left: 43.914px;
    top: 7.85px;
    position: absolute;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#close_pup_add_task_pop_up {
    position: absolute;
    cursor: pointer;
    width: 25px;
    height: 25px;
    left: 365px;
    top: 38px;
    overflow: visible;
}
#task_grp_add_task_pop_up {
    position: absolute;
    width: 321px;
    height: 62px;
    left: 60px;
    top: 259.487px;
    overflow: visible;
}
#task_lbl_add_task_pop_up {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 32px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#task_input_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.task_input_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 321px;
    height: 40px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#description_grp_add_task_pop_u {
    position: absolute;
    width: 321px;
    height: 109px;
    left: 60px;
    top: 404.496px;
    overflow: visible;
}
#description_lbl_add_task_pop_u {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 86px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#description_input_add_task_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.description_input_add_task_pop {
    position: absolute;
    overflow: visible;
    width: 321px;
    height: 86px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#category_grp_add_task_pop_up {
    position: absolute;
    width: 321px;
    height: 63px;
    left: 60px;
    top: 331.496px;
    overflow: visible;
}
#category_lbl_add_task_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#category_input_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.category_input_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 321px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#module_grp_add_task_pop_up {
    position: absolute;
    width: 321px;
    height: 63px;
    left: 60px;
    top: 186.504px;
    overflow: visible;
}
#module_lbl_add_task_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 59px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#module_input_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.module_input_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 321px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#planned_hrs_lbl_add_task_pop_u {
    position: absolute;
    width: 110.507px;
    height: 63px;
    left: 271px;
    top: 523.478px;
    overflow: visible;
}
#planned_lbll_add_task_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 111px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#planned_input_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.planned_input_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 110.507px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#status_add_task_grp {
    position: absolute;
    width: 204px;
    height: 63px;
    left: 60.1px;
    top: 523.987px;
    overflow: visible;
}
#status_lbl_add_task_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 46px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#status_input_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.status_input_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 204px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#project_input_lbl_grp {
    position: absolute;
    width: 321px;
    height: 62px;
    left: 60px;
    top: 114px;
    overflow: visible;
}
#project_lbl_add_task_pop_up {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#proeject_input_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.proeject_input_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 321px;
    height: 40px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#assigned_to_lbl_add_task_pop_u {
    left: 60.1px;
    top: 605.491px;
    position: absolute;
    overflow: visible;
    width: 91px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_task_lbl_add_task_pop_up {
    left: 177px;
    top: 62px;
    position: absolute;
    overflow: visible;
    width: 86px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#upload_lbl_add_task_pop_up {
    left: 61px;
    top: 723px;
    position: absolute;
    overflow: visible;
    width: 43px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Icon_feather-download {
    position: absolute;
    width: 23.941px;
    height: 26px;
    left: 357.059px;
    top: 718px;
    overflow: visible;
}
#arrow_bottom {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.arrow_bottom {
    overflow: visible;
    position: absolute;
    width: 26.941px;
    height: 12px;
    left: 0px;
    top: 17px;
    transform: matrix(1,0,0,1,0,0);
}
#arrow_angle {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.arrow_angle {
    overflow: visible;
    position: absolute;
    width: 19.243px;
    height: 11.121px;
    left: 4.941px;
    top: 10.5px;
    transform: matrix(1,0,0,1,0,0);
}
#arrow_line {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.arrow_line {
    overflow: visible;
    position: absolute;
    width: 3px;
    height: 21px;
    left: 12.441px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#download_invoice_add_task_pop_ {
    left: 162px;
    top: 723px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Icon_feather-upload {
    position: absolute;
    width: 23.94px;
    height: 24px;
    left: 113.06px;
    top: 719px;
    overflow: visible;
}
#invoice_down_ {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.invoice_down_ {
    overflow: visible;
    position: absolute;
    width: 26.94px;
    height: 12px;
    left: 0px;
    top: 15px;
    transform: matrix(1,0,0,1,0,0);
}
#invoice_arrow_diagnal {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.invoice_arrow_diagnal {
    overflow: visible;
    position: absolute;
    width: 19.243px;
    height: 11.121px;
    left: 4px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#invoice_line_arrow {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.invoice_line_arrow {
    overflow: visible;
    position: absolute;
    width: 3px;
    height: 21px;
    left: 11.5px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#download_drop_down__add_task_p {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.download_drop_down__add_task_p {
    position: absolute;
    overflow: visible;
    width: 120.9px;
    height: 40px;
    left: 224.55px;
    top: 711px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#assigned_to_input_add_task_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.assigned_to_input_add_task_pop {
    position: absolute;
    overflow: visible;
    width: 204.1px;
    height: 40px;
    left: 60px;
    top: 627.996px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#billed_hours_lbl_add_task_pop_ {
    left: 282px;
    top: 605.491px;
    position: absolute;
    overflow: visible;
    width: 91px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
    text-decoration: underline;
}
#n_0_hours_lbl_add_task_pop_up {
    left: 282px;
    top: 637.982px;
    position: absolute;
    overflow: visible;
    width: 91px;
    height: 22px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}