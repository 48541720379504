
	.mediaViewInfo {
		--web-view-name: task page mob;
		--web-view-id: task_page_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: task_page_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#task_page_mob {
		position: absolute;
		width: 100vw;
		height: 100vh;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: task page mob;
		--web-view-id: task_page_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#bg_design_task_page_mob_mob_1 {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#edit_btn_task_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 288px;
		top: 70px;
		overflow: visible;
	}
	#back_btn_task_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		left:12px;
		top: 70px;
		overflow: visible;
	}
	#projet_name_task_page_mob {
		left: 87.5px;
		top: 74px;
		position: absolute;
		overflow: visible;
		width: 84px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#task_page_mob_side_menu_btn {
		position: absolute;
		width: 27px;
		height: 18px;
		left: 339px;
		top: 74px;
		overflow: visible;
		cursor: pointer;
		--web-animation: ;
		--web-action-type: page;
		--web-action-target: side_component___2.html;
	}
	#side_menu_mid {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.side_menu_mid {
		overflow: visible;
		position: absolute;
		width: 30px;
		height: 3px;
		left: 0px;
		top: 9px;
		transform: matrix(1,0,0,1,0,0);
	}
	#side_menu_top {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.side_menu_top {
		overflow: visible;
		position: absolute;
		width: 30px;
		height: 3px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#side_menu_down {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.side_menu_down {
		overflow: visible;
		position: absolute;
		width: 30px;
		height: 3px;
		left: 0px;
		top: 18px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Scroll_task_page_card {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: calc(100% - 148px);
		left: 0px;
		top: 103px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#card_1_area_unassigned_mob {
		position: relative;
		width: 356px;
		height: auto;
		min-height: 35px;
		left: 10px;
		top: 5px;
		overflow: visible;
		padding-bottom: 21px;
		/* position: relative;
    width: 100%;
    height: auto;
    display:flex;
    min-height: 20px;
    left: 22px; */
    /* top: 15px; */
    /* overflow: visible; */
	}
	#top_status_area_mob_1 {
		position: absolute;
		width: 345.611px;
		height: 18px;
		left: 5px;
		top: 0px;
		overflow: visible;
	}
	#unassigned_lbl_mob_1 {
		left: 43.5px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 71px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#right-arrow_mob_1drop_btn_ {
		position: absolute;
		width: 18px;
		height: 18px;
		left: 19.5px;
		top: 0px;
		overflow: visible;
	}
	#long_line_unassigned_mob_1 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.long_line_unassigned_mob_1 {
		overflow: visible;
		position: absolute;
		width: 220.611px;
		height: 1px;
		left: 125px;
		top: 7.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_arrow_line__mob_1 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.left_arrow_line__mob_1 {
		overflow: visible;
		position: absolute;
		width: 14px;
		height: 1px;
		left: 0px;
		top: 9.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Scroll_card_1_unassigned_mob {
		mix-blend-mode: normal;
		position: relative;
		width: 100vw;
		height: auto;
		left: 0px;
		top: 18px;
		overflow-x: scroll;
		overflow-y: hidden;
		display: inline-flex;
		padding-bottom: 13px;
		
	}
	#card_1_areaassigned_mob {
		position: relative;
		width: 356px;
		height: auto;
		min-height: 35px;
		left: 10px;
		/* top: 249px; */
		overflow: visible;
		padding-bottom: 21px;

	}
	#top_status_assigned_mob {
		position: absolute;
		width: 345.611px;
		height: 18px;
		left: 5px;
		top: 0px;
		overflow: visible;
	}
	#unassigned_lbl_assigned_mob {
		left: 43.5px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 56px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#right-arrow_mob_assigned_mob {
		position: absolute;
		width: 18px;
		height: 18px;
		left: 19.5px;
		top: 0px;
		overflow: visible;
	}
	#long_line_assigned_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.long_line_assigned_mob {
		overflow: visible;
		position: absolute;
		width: 220.611px;
		height: 1px;
		left: 125px;
		top: 7.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_arrow_line__assigned_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.left_arrow_line__assigned_mob {
		overflow: visible;
		position: absolute;
		width: 14px;
		height: 1px;
		left: 0px;
		top: 9.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Scroll_card_1_assigned_mob {
		mix-blend-mode: normal;
		position: relative;
		width: 100vw;
		height: auto;
		left: 0px;
		top: 18px;
		overflow-x: scroll;
		overflow-y: hidden;
		display: inline-flex;
		padding-bottom: 13px;

	}
	#card_1_area_WIP_mob {
		position: relative;
		width: 356px;
		height: auto;
		min-height: 35px;
		left: 10px;
		/* top: 483px; */
		overflow: visible;
		padding-bottom: 21px;

	}
	#top_status_area_WIP_mob {
		position: absolute;
		width: 345.611px;
		height: 18px;
		left: 5px;
		top: 0px;
		overflow: visible;
	}
	#unassigned_lbl_mob_1_eq {
		left: 43.5px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 106px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#right-arrow_WIP_mob {
		position: absolute;
		width: 18px;
		height: 18px;
		left: 19.5px;
		top: 0px;
		overflow: visible;
	}
	#long_lineWIP_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.long_lineWIP_mob {
		overflow: visible;
		position: absolute;
		width: 193.111px;
		height: 1px;
		left: 152.5px;
		top: 7.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_arrow_lineWIP_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.left_arrow_lineWIP_mob {
		overflow: visible;
		position: absolute;
		width: 14px;
		height: 1px;
		left: 0px;
		top: 9.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Scroll_card_1_WIP_mob {
		mix-blend-mode: normal;
		position: relative;
		width: 100vw;
		height: auto;
		left: 0px;
		top: 18px;
		overflow-x: scroll;
		overflow-y: hidden;
		display: inline-flex;
		padding-bottom: 13px;

	}
	
	#card_1_area_OnHold_mob {
		position: relative;
		width: 356px;
		height: auto;
		min-height: 35px;
		left: 10px;
		/* top: 717px; */
		overflow: visible;
		padding-bottom: 21px;

	}
	#top_status_OnHold_mob {
		position: absolute;
		width: 345.611px;
		height: 18px;
		left: 5px;
		top: 0px;
		overflow: visible;
	}
	#OnHold_mob_lbl_mob_1 {
		left: 43.5px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 52px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#right-arrow_OnHold_mob_ {
		position: absolute;
		width: 18px;
		height: 18px;
		left: 19.5px;
		top: 0px;
		overflow: visible;
	}
	#long_line_unassigned__OnHold_m {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.long_line_unassigned__OnHold_m {
		overflow: visible;
		position: absolute;
		width: 220.611px;
		height: 1px;
		left: 125px;
		top: 7.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_arrow_line_OnHold_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.left_arrow_line_OnHold_mob {
		overflow: visible;
		position: absolute;
		width: 14px;
		height: 1px;
		left: 0px;
		top: 9.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Scroll_card_1_OnHold_mob {
		mix-blend-mode: normal;
		position: relative;
		width: 100vw;
		height: auto;
		left: 0px;
		top: 18px;
		overflow-x: scroll;
		overflow-y: hidden;
		display: inline-flex;
		padding-bottom: 13px;

	}
	
	#card_1_area_completed_mob {
		position: relative;
		width: 356px;
		height: auto;
		min-height: 35px;
		left: 10px;
		/* top: 951px; */
		overflow: visible;
		padding-bottom: 21px;

	}
	#top_status_area_mob_1_fv {
		position: absolute;
		width: 345.611px;
		height: 18px;
		left: 5px;
		top: 0px;
		overflow: visible;
	}
	#completed_mob_lbl_mob_1 {
		left: 43.5px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 68px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#right-arrow_mob_completed_mob_ {
		position: absolute;
		width: 18px;
		height: 18px;
		left: 19.5px;
		top: 0px;
		overflow: visible;
	}
	#long_line_completed_mob_mob_1 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.long_line_completed_mob_mob_1 {
		overflow: visible;
		position: absolute;
		width: 220.611px;
		height: 1px;
		left: 125px;
		top: 7.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_arrow_line_completed_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.left_arrow_line_completed_mob {
		overflow: visible;
		position: absolute;
		width: 14px;
		height: 1px;
		left: 0px;
		top: 9.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Scroll_card_1_completed_mob {
		mix-blend-mode: normal;
		position: relative;
		width: 100vw;
		height: auto;
		left: 0px;
		top: 18px;
		overflow-x: scroll;
		overflow-y: hidden;
		display: inline-flex;
		padding-bottom: 13px;

	}
	
	#card_1_area_cancelled_mob {
		position: relative;
		width: 356px;
		height: auto;
		min-height: 45px;
		left: 10px;
		/* top: 1185px; */
		overflow: visible;
		padding-bottom: 21px;

	}
	#top_status_cancelled_mob {
		position: absolute;
		width: 345.611px;
		height: 18px;
		left: 5px;
		top: 0px;
		overflow: visible;
	}
	#cancelled_mob_lbl_mob_1 {
		left: 43.5px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 60px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#cancelled_mob_1drop_btn_ {
		position: absolute;
		width: 18px;
		height: 18px;
		left: 19.5px;
		top: 0px;
		overflow: visible;
	}
	#long_line_cancelled_mobmob_1 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.long_line_cancelled_mobmob_1 {
		overflow: visible;
		position: absolute;
		width: 220.611px;
		height: 1px;
		left: 125px;
		top: 7.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#cancelled_mob_mob_1 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.cancelled_mob_mob_1 {
		overflow: visible;
		position: absolute;
		width: 14px;
		height: 1px;
		left: 0px;
		top: 9.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Scroll_card_1_cancelled_mob {
		mix-blend-mode: normal;
		position: relative;
		width: 100vw;
		height: auto;
		left: 0px;
		top: 18px;
		overflow-x: scroll;
		overflow-y: hidden;
		display: inline-flex;
		padding-bottom: 13px;

	}

	#caard_1_status_drop_tax_page_mob {
		mix-blend-mode: normal;
		position: relative;
		width: 174px;
		/* height: 205px; */
	 display: inline-flex;
	
		margin-right: 12px;
		
	}