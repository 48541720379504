.user_page{
    position: absolute;
    width: 100vw;
    height: 100vh;
}
.bg_overall{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: visible;


}


#userpage_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100vw - 172px);
    height: 470px;
    left: 172px;
    top: 82px;
    overflow-x: hidden;
    overflow-y: scroll;
    
}

#userpage_scroll::-webkit-scrollbar{
    display: none;
}
#user_card {
    position: relative;
    width: 188px;
    height: 191px;
    left: 0px;
    top: 14px;
    margin: 7px;
    overflow: visible;
}
#card_over_all_bg {
    fill: transparent;
}
.card_over_all_bg {
    position: absolute;
    overflow: visible;
    width: 188px;
    height: 191px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#card_bg_design {
    position: absolute;
    width: 188px;
    height: 49px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#pfp_grp {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 64px;
    top: 25px;
    overflow: visible;
    
}
#pfp_behind_circle {
    fill: rgba(255,255,255,1);
}
.pfp_behind_circle {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 63px;
    left: 7px;
    top: 0px;
}
#profile_pic_card {
    position: absolute;
    width: 41px;
    height: 41px;
    left: 9px;
    top: 2px;
    overflow: hidden;
    border-radius: 50%;
}
#email_lbl {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 131px;
    overflow: visible;
}
#affanghanigmailcom_lbl {
    left: 37px;
    top: 133px;
    position: absolute;
    overflow: visible;
    width: 134px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#iphone-5_lbl {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 162px;
    overflow: visible;
}
#n_234567890_mobile_number {
    left: 37px;
    top: 164px;
    position: absolute;
    overflow: visible;
    width: 71px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#manager_lbl {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 101px;
    overflow: visible;
}
#Admin_lbl {
    left: 37px;
    top: 103px;
    position: absolute;
    overflow: visible;
    width: 34px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#remark_lbl {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 86px;
    top: 101px;
    overflow: visible;
}
#Remark_lbl_b {
    left: 107px;
    top: 103px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Active_lbl_user {
    left: 139px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Mohammed_Affan_lbl {
    left: 35px;
    display: flex;
    justify-content: center;
    text-align: center;
    top: 74px;
    position: absolute;
    overflow: visible;
    width: 113px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Rectangle_7 {
    fill: rgba(255,255,255,1);
}
.Rectangle_7 {
    position: absolute;
    overflow: visible;
    width: 29px;
    height: 12px;
    left: 101px;
    top: 6px;
}

.switch_user{
    /* position: relative; */
    overflow: visible;
    width: 29px;
    height: 12px;
    left: 95px;
    top: -2px;
    z-index: 10;
}
#add_btn_user {
    position: absolute;
    width: 97px;
    height: 36px;
    left: 172px;
    cursor: pointer;
    top: 36px;
    overflow: visible;
}
#add_bg {
    fill: rgba(232,239,246,1);
}
.add_bg {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 115px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#Icon_ionic-md-add1 {
    fill: rgba(0,0,0,1);
}
.Icon_ionic-md-add1 {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 9.5px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
}
#Active_lbl_bn {
    left: 35px;
    top: 9px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}

/* nav css */

/* animation */
@keyframes slideInRight {
    from {
        transform: translate3d(5%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.slideInRight {
    animation-name: slideInRight;
    animation-duration: 1s;
}


