.loading {
    position: absolute;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.493);
}


.mediaViewInfo {
    --web-view-name: pup up add user mob;
    --web-view-id: pup_up_add_user_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

:root {
    --web-view-ids: pup_up_add_user_mob;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

#pup_up_add_user_mob {
    position: absolute;
    width: 375px;
    height: 666px;
    /* left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    left: 50%;
    transform: translate(-50%);

    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: pup up add user mob;
    --web-view-id: pup_up_add_user_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#all_grp_event_page_pc {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#over_all_card_pop_bg__pop_mob {
    fill: rgba(255, 255, 255, 1);
}

.over_all_card_pop_bg__pop_mob {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 379px;
    height: 667px;
    left: 0px;
    top: 0px;
}

#bg_design_grp_pop_mob {
    position: absolute;
    width: 361px;
    height: 121.174px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#bg_design_2_pop_mob {
    fill: rgba(219, 242, 231, 1);
}

.bg_design_2_pop_mob {
    overflow: visible;
    position: absolute;
    width: 361px;
    height: 121.174px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#bg_design_1_pop_mob {
    fill: rgba(219, 242, 231, 1);
}

.bg_design_1_pop_mob {
    overflow: visible;
    position: absolute;
    width: 361px;
    height: 121.174px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#state_grp_prof_pop_mob {
    position: absolute;
    width: 321.33px;
    height: 63px;
    left: 19.113px;
    top: 208.504px;
    overflow: visible;
}

#state_lbl_pfp_pop_mob {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 46px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#state_input_pfp_pop_mob {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.state_input_pfp_pop_mob {
    position: absolute;
    overflow: visible;
    width: 321.33px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}

#city_grp_prof_pop_mob {
    position: absolute;
    width: 147.89px;
    height: 60px;
    left: 192px;
    top: 284.509px;
    overflow: visible;
}

#city_lbl_pfp_pop_mob {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0, 0, 0, 1);
}

#city_input_pfp_pop_mob {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.city_input_pfp_pop_mob {
    position: absolute;
    overflow: visible;
    width: 147.89px;
    height: 40px;
    left: 0px;
    top: 20px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}

#company_grp_pop_mob {
    position: absolute;
    width: 147.887px;
    height: 63px;
    left: 19.113px;
    top: 281.504px;
    overflow: visible;
}

#company_lbl_pfp_pop_mob {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#company_input_pfp_pop_mob {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.company_input_pfp_pop_mob {
    position: absolute;
    overflow: visible;
    width: 147.887px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}

#user_type_grp_pop_mob {
    position: absolute;
    width: 147.665px;
    height: 63px;
    left: 19.335px;
    top: 354.513px;
    overflow: visible;
}

#user_type_lbl_pfp_pop_mob {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 74px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#user_type_input_pfp_pop_mob {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.user_type_input_pfp_pop_mob {
    position: absolute;
    overflow: visible;
    width: 147.665px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}

#latitiude_grp_pop_mob_ {
    position: absolute;
    width: 321.33px;
    height: 107px;
    left: 19.113px;
    top: 427.518px;
    overflow: visible;
}

#latitude_lbl_pop_mob {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#latitude_input_pop_mob {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.latitude_input_pop_mob {
    position: absolute;
    overflow: visible;
    width: 321.33px;
    height: 84px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}

#active_lbl_pop_mob {
    left: 182.286px;
    top: 141.004px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
}

#active_chkbx_pop_mob {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.active_chkbx_pop_mob {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 20px;
    left: 138.714px;
    top: 145.004px;
}

#change_pass_btn_grp_pop_mob:hover {
    top: 580px;
}

#change_pass_btn_grp_pop_mob {
    position: absolute;
    width: 123px;
    height: 41px;
    left: 119px;
    top: 575px;
    overflow: visible;
    border-radius: 20px;
    transition: all .3s ease-out;
    cursor: pointer;
}

#passs_bdr_btn_pop_mob {
    fill: rgba(219, 242, 231, 1);
}

.passs_bdr_btn_pop_mob {
    position: absolute;
    overflow: visible;
    width: 123px;
    height: 41px;
    left: 0px;
    top: 0px;
}

#Change_Password_lbl_pop_mob {
    left: 43.914px;
    top: 7.28px;
    position: absolute;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
}

#close_pop_up_use_close_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 325px;
    top: 13px;
    overflow: visible;
    cursor: pointer;
}

#User_access_grp_pop_mob {
    position: absolute;
    width: 147.665px;
    height: 63px;
    left: 193px;
    top: 354.513px;
    overflow: visible;
}

#User_access_lbl_pfp_pop_mob {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 89px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#User_access_input_pfp_pop_mob {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.User_access_input_pfp_pop_mob {
    position: absolute;
    overflow: visible;
    width: 147.665px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}

#circle_img_holder_pop_mob_grp {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 36%;
    top: 19px;
    overflow: visible;
}

#pfp_behind_circle_pop_mob {
    fill: rgba(255, 255, 255, 1);
}

.pfp_behind_circle_pop_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 118px;
    height: 118px;
    left: 0px;
    top: 0px;
}

#profile_pfp_img_pop_mob {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 9.913px;
    top: 9.004px;
    overflow: hidden;
    box-shadow: box-shadow;
    background: #e0e0e0;
    border-radius: 50%;
}

.popup_switch {
    left: -99px;
    top: 1px;
}

#user_resetpass {
    position: absolute;
    width: 30px;
    height: 30px;
    z-index: 10;
    top: 40px;
    left: -8px;
}