.mediaViewInfo {
    --web-view-name: search component;
    --web-view-id: search_component;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: search_component;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#search_component {
    position: absolute;
    width: calc(100% - 300px);
    height: 84px;
    left: 154px;
    z-index: 1;
    right: 0px;
    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: search component;
    --web-view-id: search_component;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#search_place {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.search_place {
    position: absolute;
    overflow: visible;
    width: calc(100% - 200px);
    height: 38px;
    left: 108px;
    top: 24px;
    border-radius:  10px;
    padding-left: 10px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#search {
    position: absolute;
    width: 20px;
    height: 20px;
    left: calc(100% - 120px);
    top: 32px;
    overflow: visible;
}
#add_btn_overall_page {
    position: absolute;
    width: 68px;
    height: 36px;
    left: 20px;
    top: 26px;
    overflow: visible;
    cursor: pointer;
}
#add_bg_overall_page {
    fill: rgba(232,239,246,1);
}
.add_bg_overall_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 86px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#Icon_ionic-searchpage {
    fill: rgba(0,0,0,1);
}
.Icon_ionic-searchpage {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 9.5px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
}
#add_overall_lbl_page {
    left: 35px;
    top: 9px;
    position: absolute;
    overflow: visible;
    width: 25px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}

