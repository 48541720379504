.mediaViewInfo {
    --web-view-name: pup up add staff;
    --web-view-id: pup_up_add_staff;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pup_up_add_staff;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pup_up_add_staff {
    position: absolute;
    width: 442px;
    cursor: pointer;
    height: 789px;
    left: 50%;
    transform: translate(-50%);
    /* top: 70%;
    left: 50%;
    transform: translate(-50%, -50%); */
    /* background-color: rgba(255,255,255,1); */
    /* overflow-x: hidden;
    overflow-y: scroll; */
    --web-view-name: pup up add staff;
    --web-view-id: pup_up_add_staff;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_staff_page {
    fill: rgba(255,255,255,1);
}
.over_all_staff_page {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 379px;
    height: 746px;
    left: 39px;
    top: 28px;
}
#top_bg_staff_page_y {
    fill: url(#top_bg_staff_page_y);
}
.top_bg_staff_page_y {
    position: absolute;
    overflow: visible;
    width: 361px;
    height: 94px;
    left: 39px;
    top: 28px;
}
#pop_up_add_staff_pfp {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 170px;
    top: 38px;
    overflow: visible;
}
/* #pfp_behind_circle_bg_staff {
    fill: rgba(255,255,255,1);
} */
.pfp_behind_circle_bg_staff {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 118px;
    height: 118px;
    left: 0px;
    top: 0px;
}
#profile_pfp_img_staff {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 10px;
    top: 9.004px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow:   box-shadow;
    background:  #e0e0e0;
    border-radius: 50px;
}
#staff_name_grp_prof {
    position: absolute;
    width: 321.329px;
    height: 62px;
    left: 59px;
    top: 186.504px;
    overflow: visible;
}
#staff_name__lbl_pfp {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 84px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#staff_name__input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.staff_name__input_pfp {
    position: absolute;
    overflow: visible;
    width: 321.329px;
    height: 40px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#staff_designation_grp_prof {
    position: absolute;
    width: 321.329px;
    height: 63px;
    left: 59px;
    top: 258.496px;
    overflow: visible;
}
#staff_designation_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 129px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#staff_designationinput_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.staff_designationinput_pfp {
    position: absolute;
    overflow: visible;
    width: 321.329px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#staff_skill_grp {
    position: absolute;
    width: 321.579px;
    height: 63px;
    left: 59px;
    top:  477.504px;
    overflow: visible;
}
#staff_skill_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#staff_skill_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.staff_skill_input_pfp {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 84px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#staff_exp_grp {
    position: absolute;
    width: 321.8px;
    height: 63px;
    left: 59px;
    top: 331.496px;
    overflow: visible;
}
#staff_exp_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#staff_exp_input_pfp_year {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.staff_exp_input_pfp_year {
    position: absolute;
    overflow: visible;
    width: 160px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#staff_exp_input_pfp_month {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.staff_exp_input_pfp_month {
    position: absolute;
    overflow: visible;
    width: 160px;
    height: 40px;
    left: 162px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}

#remark_grp {
    position: absolute;
    width: 321.579px;
    height: 107px;
    left: 59px;
    top: 589.504px;
    overflow: visible;
}
#remark_pup_op_staff_lbl {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#remark_pup_op_staff_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.remark_pup_op_staff_input {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 84px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#active_grp_staff {
    position: absolute;
    width: 97.572px;
    height: 27px;
    left: 171px;
    top: 146.004px;
    overflow: visible;
}

#active_lbl_staff {
    left: 43.572px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#active_chkbx {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.active_chkbx {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 20px;
    left: 0px;
    top: 4px;
}
#Add_pup_op_staff_btn_grp:hover {
    top:719px ;
}
#Add_pup_op_staff_btn_grp {
    position: absolute;
    width: 123px;
    height: 41px;
    left: 162px;
    top: 715px;
    overflow: visible;
    border-radius: 20px;
    transition: all .3s ease-out;
}
#addd_bdr_btn_staff {
    fill: rgba(192,255,219,1);
}
.addd_bdr_btn_staff {
    position: absolute;
    overflow: visible;
    width: 123px;
    height: 41px;
    left: 0px;
    top: 0px;
}
#Add_pup_op_staff_lbl {
    left: 43.914px;
    top: 7.28px;
    position: absolute;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#staff_mob_grp {
    position: absolute;
    width: 321.579px;
    height: 63px;
    left: 59px;
    top: 404.504px;
    overflow: visible;
}
#staff_mob_lbl_pup_op_staff {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#staff_mob_pup_op_staff {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.staff_mob_pup_op_staff {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#close_pup_op_staff {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 365px;
    top: 38px;
    overflow: visible;
}
.switch_staff{
    left: -26px;
    top: 3px;
}