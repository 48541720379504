.mediaViewInfo {
    --web-view-name: client staff mob;
    --web-view-id: client_staff_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: client_staff_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#client_staff_mob {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: client staff mob;
    --web-view-id: client_staff_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_bg_design_client_staf {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Scroll_client_staff_mob_new {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 65px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#card_1_client_staff_mob_new {
    position: relative;
    width: 351px;
    height: 105px;
    left: 12px;
    top: 6px;
    overflow: visible;
}
#card_over_all_bg_client_staff_ {
    fill: transparent;
}
.card_over_all_bg_client_staff_ {
    position: absolute;
    overflow: visible;
    width: 351px;
    height: 105px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#card_bg_design_client_staff_mo {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 0px;
    top: 3px;
    overflow: visible;
}
#pfp_behind_circleclient_staff_ {
    fill: rgba(255,255,255,1);
}
.pfp_behind_circleclient_staff_ {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 104px;
    height: 104px;
    left: 7px;
    top: 10px;
}
#profile_pic_cardclient_staff_m {
    position: absolute;
    width: 82px;
    height: 82px;
    left: 9px;
    top: 12px;
    overflow: visible;
}
#iphone-5_lbl_client_staff_mob_ {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 120px;
    top: 83px;
    overflow: visible;
}
#n_234567890_mobile_number_clie {
    left: 147px;
    top: 85px;
    position: absolute;
    overflow: visible;
    width: 71px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Mohammed_Affan_lbl_client_staf {
    left: 120px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 226px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#exp_client_staff_mob_new {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 120px;
    top: 53px;
    overflow: visible;
}
#yr_client_pc_new_client_staff_ {
    left: 147px;
    top: 55px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#manager_lbl_client_staff_mob_n {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 120px;
    top: 23px;
    overflow: visible;
}
#Admin_lbl_client_staff_mob_new {
    left: 147px;
    top: 25px;
    position: absolute;
    overflow: visible;
    width: 90px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}