.mediaViewInfo {
    --web-view-name: pup up task page team;
    --web-view-id: pup_up_task_page_team;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pup_up_task_page_team;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pup_up_task_page_team {
    position: absolute;
    width: 362px;
    height: 495px;
    left: 50%;
    top: 50%;
    transform: translate(-50% , -50%);
    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: pup up task page team;
    --web-view-id: pup_up_task_page_team;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_bg_add_add_team_bg {
    fill: rgba(255,255,255,1);
}
.over_all_bg_add_add_team_bg {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 369px;
    height: 485px;
    left: 0px;
    top: 14px;
}
#top_bg_add_add_team_up_c {
    fill: url(#top_bg_add_add_team_up_c);
}
.top_bg_add_add_team_up_c {
    position: absolute;
    overflow: visible;
    width: 351px;
    height: 94px;
    left: 0px;
    top: 0px;
}
/* #add_add_add_team:hover {
    left: 8px;
    top: 606px;
} */
#add_add_add_team {
    position: absolute;
    width: 123px;
    height: 41px;
    left: 111px;
    top: 400px;
    overflow: visible;
    border-radius: 20px;
    transition: all .3s ease-out;
}
#addd_bdr_btn_add_add_team {
    fill: rgba(192,255,219,1);
}
.addd_bdr_btn_add_add_team {
    position: absolute;
    overflow: visible;
    width: 123px;
    height: 41px;
    left: 0px;
    top: 0px;
}
#Add_add_add_team_lbl {
    left: 43.914px;
    top: 7.849px;
    position: absolute;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#close_pup_add_add_team {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 316px;
    top: 10px;
    overflow: visible;
    cursor: pointer;
}
#allocation_start_grp_add_team {
    position: absolute;
    width: 116.725px;
    height: 60px;
    left: 15px;
    top: 233.487px;
    overflow: visible;
}
#allocation_start_lbl_add_team {
    left: 1.112px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 102px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#alocation_input_add_team {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.alocation_input_add_team {
    position: absolute;
    overflow: visible;
    width: 116.725px;
    height: 40px;
    left: 0px;
    top: 20px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#unit_price_grp_add_team {
    position: absolute;
    width: 150px;
    height: 63px;
    left: 185.585px;
    top: 303px;
    overflow: visible;
}
#unit_price_add_team_lbl {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 73px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#unit_price_input_add_team {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.unit_price_input_add_team {
    position: absolute;
    overflow: visible;
    width: 150px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#billing_grp_add_team {
    position: absolute;
    width: 150px;
    height: 63px;
    left: 15px;
    top: 303.496px;
    overflow: visible;
}
#billing_unit_add_team {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#billing_input_add_team {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.billing_input_add_team {
    position: absolute;
    overflow: visible;
    width: 150px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#role_grp_add_team {
    position: absolute;
    width: 321px;
    height: 63px;
    left: 15px;
    top: 158.504px;
    overflow: visible;
}
#role_lbl_add_team {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 34px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#role_input_add_team {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.role_input_add_team {
    position: absolute;
    overflow: visible;
    width: 321px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#staff_grp_add_team {
    position: absolute;
    width: 321px;
    height: 62px;
    left: 15px;
    top: 86px;
    overflow: visible;
}
#staff_lbl_add_team {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 35px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#staff_inpiut_add_team {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.staff_inpiut_add_team {
    position: absolute;
    overflow: visible;
    width: 321px;
    height: 40px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#add_team_memebr_lbl_add_team {
    left: 86px;
    top: 34px;
    position: absolute;
    overflow: visible;
    width: 180px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#allocation_ends_grp_add_team {
    position: absolute;
    width: 116.725px;
    height: 60px;
    left: 135.06px;
    top: 233.487px;
    overflow: visible;
}
#allocation_lbl_add_team {
    left: 1.112px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 103px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#allocation_ends_input_add_team {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.allocation_ends_input_add_team {
    position: absolute;
    overflow: visible;
    width: 116.725px;
    height: 40px;
    left: 0px;
    top: 20px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#allocation_grp_add_team {
    position: absolute;
    width: 83px;
    height: 60px;
    left: 255.714px;
    top: 233.496px;
    overflow: visible;
}
#allocation_lbl_add_team_dx {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 84px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#allocation_input__add_team {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.allocation_input__add_team {
    position: absolute;
    overflow: visible;
    width: 78.286px;
    height: 40px;
    left: 2px;
    top: 20px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}