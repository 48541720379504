.mediaViewInfo {
    --web-view-name: time sheet page;
    --web-view-id: time_sheet_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: time_sheet_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#time_sheet_page {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: time sheet page;
    --web-view-id: time_sheet_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bg_design_mob_mob_1 {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#card_1_area_mob {
    position: relative;
    width: 100%;
    height: 229px;
    left: 10px;
    top: 10px;
    overflow: visible;
    display: block;
}
#top_mob_1 {
    position: absolute;
    width: 345.611px;
    height: 19px;
    left: 5px;
    top: 0px;
    overflow: visible;
}
#n_lbl_mob_1 {
    left: 43.5px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 71px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#rightmob_1drop_btn_ {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 19.5px;
    top: 0px;
    overflow: visible;
}
#long_line_mob_1 {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.long_line_mob_1 {
    overflow: visible;
    position: absolute;
    width: 220.611px;
    height: 1px;
    left: 125px;
    top: 7.5px;
    transform: matrix(1,0,0,1,0,0);
}
#left_ar_mob_1 {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.left_ar_mob_1 {
    overflow: visible;
    position: absolute;
    width: 14px;
    height: 1px;
    left: 0px;
    top: 9.5px;
    transform: matrix(1,0,0,1,0,0);
}
#Scroll_card_1_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 91%;
    left: 0px;
    top: 18px;
    overflow-x: scroll;
    overflow-y: hidden;
}
#billedcard_timesheetcard_{
    position: absolute;
    width: 100%;
    height: 200px;
    left: 0px;
    top: 5px;
    display: flex;
    
}
#timesheet_scroll{
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 90vh;
    left: 0px;
    top: 60px;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* #pop_up_1drop_btn_ {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 327.5px;
    top: 85px;
    overflow: visible;
} */