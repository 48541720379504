.mediaViewInfo {
    --web-view-name: chamge pass;
    --web-view-id: chamge_pass;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

:root {
    --web-view-ids: chamge_pass;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

#chamge_pass {
    position: absolute;
    width: 283px;
    height: 296px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background-color: rgba(255, 255, 255, 1);
    overflow: hidden;
    --web-view-name: chamge pass;
    --web-view-id: chamge_pass;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#overall_grp_change_pass {
    position: absolute;
    width: 361px;
    height: 274px;
    left: -22px;
    top: -5px;
    overflow: visible;
}

#top_bg_change_pass_c {
    fill: url(#top_bg_change_pass_c);
}

.top_bg_change_pass_c {
    position: absolute;
    overflow: visible;
    width: 361px;
    height: 60px;
    left: 0px;
    top: 0px;
}

#close_pup_op_change_passs {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 270px;
    top: 10px;
    overflow: visible;
    cursor: pointer;
}

#Change_Password_lbl_ {
    left: 94px;
    top: 13px;
    position: absolute;
    overflow: visible;
    width: 141px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#old_pass_input {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.old_pass_input {
    position: absolute;
    overflow: visible;
    width: 255px;
    height: 40px;
    left: 36px;
    top: 65px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}

#new_pass_input {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.new_pass_input {
    position: absolute;
    overflow: visible;
    width: 255px;
    height: 40px;
    left: 36px;
    top: 120px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}

#confirm_new_pass_input {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.confirm_new_pass_input {
    position: absolute;
    overflow: visible;
    width: 255px;
    height: 40px;
    left: 36px;
    top: 175px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}

#btn_change_password {
    position: absolute;
    width: 131px;
    height: 39px;
    left: 97px;
    top: 235px;
    overflow: visible;
}

#change_btn_ {
    fill: rgba(199, 223, 255, 1);
}

.change_btn_ {
    position: absolute;
    overflow: visible;
    width: 131px;
    height: 39px;
    left: 0px;
    top: 0px;
}

#Change_lbl_ {
    left: 40px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0, 0, 0, 1);
}