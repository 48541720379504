 .mediaViewInfo {
    --web-view-name: project page;
    --web-view-id: project_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: project_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#project_page {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: project page;
    --web-view-id: project_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
} 
#over_all_bg_project_page {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    left:0px;
    top: 0px;
    /* overflow: visible; */
}
#add_btn_project_page {
    position: absolute;
    width: 111px;
    height: 36px;
    left: 172px;
    top: 36px;
    overflow: visible;
}
#add_bg_project_page {
    fill: rgba(232,239,246,1);
}
.add_bg_project_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 129px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#Icon_ionic-project_page {
    fill: rgba(0,0,0,1);
}
.Icon_ionic-project_page {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 9.5px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
}
#add_project_lbl_page {
    left: 35px;
    top: 9px;
    cursor: pointer;
    position: absolute;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}



/* scroll */

#project_page_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100vw - 185px);
    height: 452px;
    left: 172px;
    top: 88px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#project_page_scroll::-webkit-scrollbar{
    display: none;
}
#card_1_project {
    /* position: absolute; */
    width: 175px;
    height: 194px;
    left: 20px;
    top: 14px;
    overflow: visible;
    margin: 7px;
}
#card_over_all_bg_project_page {
    fill: transparent;
}
.card_over_all_bg_project_page {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 194px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#card_bg_design_project_page {
    position: absolute;
    width: 174px;
    height: 49px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#pfp_grp_project_page {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 64px;
    top: 25px;
    overflow: visible;
}
#pfp_behind_circle_project_page {
    fill: rgba(119,238,170,1);
}
.pfp_behind_circle_project_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 63px;
    left: 0px;
    top: 0px;
}
#profile_pic_card_project_page {
    position: absolute;
    width: 41px;
    height: 41px;
    left: 2px;
    top: 2px;
    overflow: hidden;
    border-radius: 50%;
}
#manager_lbl_project_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 101px;
    overflow: visible;
}
#Admin_lbl_project_page {
    left: 37px;
    top: 103px;
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Mohammed_Affan_lbl_project_page {
    left: 0px;
    top: 74px;
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 17px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Start_time_projpage {
    left: 8px;
    top: 134px;
    position: absolute;
    overflow: visible;
    width: 81px;
    height: 17px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#date_proj_page {
    left: 97px;
    top: 134px;
    position: absolute;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#road_time_grp {
    position: absolute;
    width: 165px;
    height: 34px;
    left: 10px;
    top: 155px;
    overflow: visible;
}
#road_bdr {
    opacity: 0.75;
    fill: rgba(133,133,133,1);
}
.road_bdr {
    position: absolute;
    overflow: visible;
    width: 155px;
    height: 25px;
    left: 0px;
    top: 9px;
}
#proj_road_line {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.proj_road_line {
    overflow: visible;
    position: absolute;
    width: 140px;
    height: 1px;
    left: 7.5px;
    top: 21.5px;
    transform: matrix(1,0,0,1,0,0);
}
#proj_startpage {
    position: absolute;
    width: 20px;
    height: 20px;
    /* left: 0px; */
    top: 2px;
    /* overflow: visible; */
}
#finish_img_pro_page {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 140px;
    top: 0px;
    overflow: visible;
}