
.mediaViewInfo {
    --web-view-name: timeline page;
    --web-view-id: timeline_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: timeline_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#timeline_page {
    position: absolute;
    width: 100vw;
    height: 100vh;
    /* left: 145px; */
    /* display:inline-flex; */
    /* background-color: rgba(255,255,255,1); */
    --web-view-name: timeline page;
    --web-view-id: timeline_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_bg_timeline_page {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    overflow: hidden;
}
#add_timesheet_bar{
    position: absolute;
    width: calc(100% - 145px);
    margin-right: 0px;
    height: 100vh;
    left: 145px;
    overflow: hidden;
}
#calendar_timeline_page {
    position: absolute;
    width: 38px;
    height: 38px;
    left:5px;
    top: 101px;
    overflow: visible;
}
#task_drop_down_timeline_page {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.task_drop_down_timeline_page {
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 36px;
    left: 384px;
    top: 104px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}

#project_drop_down_timeline_page {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.project_drop_down_timeline_page {
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 36px;
    left: 194px;
    top: 104px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}
#hours_inout_timeline_page {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.hours_inout_timeline_page {
    position: absolute;
    overflow: visible;
    width: 85px;
    height: 36px;
    left: 577px;
    top: 104px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#status_drop_down_timeline_page {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.status_drop_down_timeline_page {
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 36px;
    left: 672px;
    top: 104px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#add_btn_timeline_page {
    position: absolute;
    width: 68px;
    height: 36px;
    left: 885px;
    top: 104px;
    overflow: visible;
    border-radius: 10px;
    cursor: pointer;
}
#excel_btn_timeline_page {
    position: absolute;
    width: 80px;
    height: 36px;
    /* left: 885px; */
    right:150px ;
    top: 104px;
    overflow: visible;
    border-radius: 10px;
    fill: rgba(232,239,246,1);
    cursor: pointer;
}
#add_bg_timeline_page {
    fill: rgba(232,239,246,1);
}
.add_bg_timeline_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 86px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#Icon_ionic-timeline_page {
    fill: rgba(0,0,0,1);
}
.Icon_ionic-timeline_page {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 9.5px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
}
#add_timeline_page {
    left: 35px;
    top: 9px;
    position: absolute;
    overflow: visible;
    width: 25px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}

#time_sheet_switch{
    position: absolute;
    /* width: 68px; */
    /* height: 36px; */
    /* left: 985px; */
    right: 30px;
    top: 112px;
    overflow: visible;
    /* border-radius: 10px; */
}
#excel_bg_timeline_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 5px;
    top: 5px;
}

#date_calender {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.date_calender {
    position: absolute;
    overflow: visible;
    width: 137px;
    height: 36px;
    left: 49px;
    top: 104px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
