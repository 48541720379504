.mediaViewInfo {
    --web-view-name: pc login – 1;
    --web-view-id: pc_login__1;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pc_login__1;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pc_login__1 {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: pc login – 1;
    --web-view-id: pc_login__1;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#emile-perron-xrVDYZRGdw4-unspl {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#view {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 1095px;
    top: 364px;
    overflow: visible;
}
#Group_1 {
    position: absolute;
    width: 155px;
    height: 200px;
    left: 1211px;
    top: 568px;
    overflow: visible;
}
#mid_overall {
    position: absolute;
    width: 392px;
    height: 458px;
    left: 50%;
    top: 50%;
    overflow: visible;
    transform: translate(-50%, -50%);
}
#bg_dummy {
    fill: transparent;
}
.bg_dummy {
    position: absolute;
    overflow: visible;
    width: 392px;
    height: 413px;
    left: 0px;
    top: 45px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 20px );
    -webkit-backdrop-filter:  blur( 20px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#logo {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 151px;
    top: 0px;
    overflow: visible;
}
#SIGN_IN {
    left: 62px;
    top: 124px;
    position: absolute;
    overflow: visible;
    width: 142px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: #E21B1B;
}
#Sign_in_with_email {
    left: 44px;
    top: 167px;
    position: absolute;
    overflow: visible;
    width: 148px;
    white-space: nowrap;
    text-align: left;
    font-family: Berlin Sans FB;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#username {
    fill: rgba(254,254,254,1);
}
.username {
    position: absolute;
    overflow: visible;
    width: 305px;
    height: 46px;
    left: 44px;
    top: 188px;
    border-radius: 5px;
    padding: 5px;
    background: transparent;
margin-bottom: 30px;
border-bottom: solid seagreen;
}
#btn {
    position: absolute;
    width: 305px;
    height: 45px;
    left: 44px;
    top: 348px;
    overflow: visible;
    border-radius: 5px;
    cursor: pointer;
}
#Rectangle_3_r {
    fill: url(#Rectangle_3_r);
}
.Rectangle_3_r {
    position: absolute;
    overflow: visible;
    width: 305px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#Sign_in {
    left: 126px;
    top: 12px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Berlin Sans FB;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#password {
    fill: rgba(255,255,255,1);
}
.password {
    position: absolute;
    overflow: visible;
    width: 305px;
    height: 46px;
    left: 44px;
    top: 248px;
    border-radius: 5px;
    padding: 5px;
    background: transparent;
margin-bottom: 30px;
border-bottom: solid seagreen;
}
#Dont_have_a_account_Sign_up {
    left: 140px;
    top: 413px;
    position: absolute;
    overflow: visible;
    width: 202px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#Forgot_Password {
    left: 231px;
    top: 296px;
    position: absolute;
    overflow: visible;
    width: 111px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,71,71,1);
    cursor: pointer;
}