.mediaViewInfo {
    --web-view-name: extend nav left project;
    --web-view-id: extend_nav_left_project;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: extend_nav_left_project;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#extend_nav_left_project {
    position: absolute;
    z-index: 5;
    width: 283px;
    height: 100vh;
    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: extend nav left project;
    --web-view-id: extend_nav_left_project;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Group_3 {
    position: absolute;
    width: 246px;
    height: 87vh;
    left: 30px;
    top: 36px;
    overflow: visible;
}
#side_nav_bar_border {
    fill: rgba(219,242,231,1);
}

.side_nav_bar_border {
    position: absolute;
    overflow: visible;
    width: 246px;
    height: 87vh;
    left: 0px;
    top: 0px;
}
#over_all_grp_expand {
    position: absolute;
    width: 195px;
    height: 439px;
    left: 30px;
    top: 28px;
    overflow: visible;
    display: grid;
}
#side_logo_icon_expand {
    position: relative;
    width: 70px;
    height: 70px;
    left: 48px;
    /* top: 0px; */
    overflow: hidden;
    border-radius: 50%;
}
#dash_bdr_grp_expand {
    position: relative;
    width: 195px;
    height: 43px;
    left: 3px;
    /* top: 160px; */
    overflow: visible;
    cursor: pointer;
}
#dash_bdr_grp_expand_client {
    position: relative;
    width: 200px;
    height: 43px;
    left: 3px;
    /* top: 100px; */
    overflow: visible;
    cursor: pointer;

}

#dash_pc {
    fill: rgba(255,255,255,1);
}
.dash_pc {
    position: absolute;
    overflow: visible;
    width: 195px;
    height: 43px;
    left: 0px;
    top: 0px;
}
#Dashboard_side_lbl_pc {
    left: 50px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#dashboard_Icon_I_1 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 8px;
    top: 6px;
    overflow: visible;
}
#offering_grp_expand {
    position: relative;
    width: 195px;
    height: 43px;
    left: 3px;
    /* top: 340px; */
    overflow: visible;
    cursor: pointer;
}
#offering_grp_expand_timesheet {
    position: relative;
    width: 195px;
    height: 43px;
    left: 3px;
    /* top: 400px; */
    overflow: visible;
    cursor: pointer;
}
#Offerings_side_lbl_pc {
    left: 50px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#offerings_icon_I_1 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 8px;
    top: 5px;
    overflow: visible;
}
#connection_grp_expand {
    position: relative;
    width: 195px;
    height: 43px;
    left: 3px;
    /* top: 280px; */
    overflow: visible;
    cursor: pointer;
}
#My_Connection_side_lbl_pc {
    left: 50px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#my_connections_icon_I_1 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 8px;
    top: 5px;
    overflow: visible;
}
#com_grp_expand {
    position: relative;
    width: 195px;
    height: 43px;
    left: 3px;
    /* top: 220px; */
    overflow: visible;
    cursor: pointer;
}
#Communities_side_lbl_pc {
    left: 50px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 71px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#community_icon_I_1 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 8px;
    top: 5px;
    overflow: visible;
}
#com_grp_expand_clients {
    position: relative;
    width: 195px;
    height: 43px;
    left: 3px;
    /* top: 220px; */
    overflow: visible;
    cursor: pointer;
}
#Communities_side_lbl_pc_clients {
    left: 50px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 71px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#community_icon_I_1_clients {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 8px;
    top: 5px;
    overflow: visible;
}
#offering_grp_expand_ {
    position: relative;
    width: 112px;
    height: 30px;
    left: 11px;
    /* top: 455px; */
    overflow: visible;
    cursor: pointer;
}
#Offerings_side_lbl_pc_ {
    left: 45px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 73px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#logout_icon_I {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 3px;
    top: 5px;
    overflow: visible;
}
.highlight2{
    border: 0px solid rgb(241, 239, 239);
    border-radius: 7px;
    
}
.highlight2:hover{
    background-color: white;
}

/* Animation */
/* @keyframes slideInLeft {
    from {
      transform: translate3d(-10%, 0, 0);
      visibility: visible;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInLeft {
    animation-name: slideInLeft;
    animation-duration: 4s;
  } */
  .slide-right{
    animation: slide-right 0.4s linear both;
  }
  @keyframes slide-right {
    0%{
        transform:translate(-100px)
    }
    100%{
        transform:translate(0px)
    }
    
  }

 