.mediaViewInfo {
    --web-view-name: Footer app candi pc;
    --web-view-id: Footer_app_candi_pc;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Footer_app_candi_pc;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#Footer_app_candi_pc {
    z-index: 1;
    position: fixed;
    bottom: 0px;
    width: 100vw;
    height: 33px;
    /* background-color: rgba(255,255,255,1); */

    overflow: hidden;
    --web-view-name: Footer app candi pc;
    --web-view-id: Footer_app_candi_pc;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#footer_app_pc_candi {
    position: absolute;
    width: 100vw;
    height: 34px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#footer_vacan {
    fill: rgba(116,226,173,1);
}
.footer_vacan {
    position: absolute;
    overflow: visible;
    width: 1349px;
    height: 64px;
    left: 0px;
    top: 0px;
}
#All_rights_reserved__2022_Oper {
    left: 30px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 491px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255,255,255,1);
}
#linkedin_vacan {
    position: absolute;
    width: 30px;
    height: 30px;
    /* left: 88%; */
    right: 60px;
    top: 2px;
    overflow: visible;
}
#whatapp_btn_img_vacan {
    position: absolute;
    width: 28px;
    height: 28px;
    /* left: 91%; */
    right: 20px;
    top: 2px;
    overflow: visible;
}
#term_and_pol_grp {
    position: absolute;
    width: 300px;
    height: 24.5px;
    left: calc(100vw/2);
    top: 4px;
    overflow: visible;
}
#Terms_and_Conditions_lbl_vac {
    left: 7%;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 173px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255,255,255,1);
}
#Private_Policy_lbl_vac {
    left: 63%;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 106px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Line_2_vacan {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_2_vacan {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    left: 183.5px;
    top: 0.5px;
    transform: matrix(1,0,0,1,0,0);
}