.mediaViewInfo {
    --web-view-name: pup up add staff;
    --web-view-id: pup_up_add_staff;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pup_up_add_staff;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pup_up_add_staff {
    position: absolute;
    width: 442px;
    height: 862px;
    left: 50%;
    transform: translate(-50%);
    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: pup up add staff;
    --web-view-id: pup_up_add_staff;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all__client_pop_pc {
    fill: rgba(255,255,255,1);
}
.over_all__client_pop_pc {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 379px;
    height: 790px;
    left: 39px;
    top: 28px;
}
#top_bg__client_pop_pc_c {
    fill: url(#top_bg__client_pop_pc_c);
}
.top_bg__client_pop_pc_c {
    position: absolute;
    overflow: visible;
    width: 361px;
    height: 94px;
    left: 39px;
    top: 28px;
}
#pop_up__client_pop_pc {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 170px;
    top: 38px;
    overflow: visible;
}
/* #pfp_behind_circle_bg__client_p {
    fill: rgba(255,255,255,1);
} */
.pfp_behind_circle_bg__client_p {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 118px;
    height: 118px;
    left: 0px;
    top: 0px;
}
#profile_pfp_img__client_pop_pc {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 10px;
    top: 9.004px;
    overflow: hidden;
    box-shadow:   box-shadow;
    background:  #e0e0e0;
    border-radius: 50%;
}
#client_pop_pcname_grp_prof {
    position: absolute;
    width: 321.329px;
    height: 62px;
    left: 59.171px;
    top: 259.513px;
    overflow: visible;
}
#client_pop_pc_lbl_pfp {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 132px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#client_pop_pc_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.client_pop_pc_input_pfp {
    position: absolute;
    overflow: visible;
    width: 321.329px;
    height: 40px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#client_pop_pcdesignation_grp_p {
    position: absolute;
    width: 321.329px;
    height: 63px;
    left: 59.171px;
    top: 331.504px;
    overflow: visible;
}
#client_pop_pc_designation_lbl_ {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 139px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#client_pop_pcdesignationinput_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.client_pop_pcdesignationinput_ {
    position: absolute;
    overflow: visible;
    width: 321.329px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#staff_skill_grp_client_pop_pc {
    position: absolute;
    width: 321.579px;
    height: 105px;
    left: 59px;
    top: 477.504px;
    overflow: visible;
}
#client_pop_pc_lbl_pfp_dd {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 94px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#client_pop_pc_input_pfp_de {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.client_pop_pc_input_pfp_de {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 82px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#staff_exp_grp_client_pop_pc {
    position: absolute;
    width: 321.8px;
    height: 63px;
    left: 59.7px;
    top: 186.513px;
    overflow: visible;
}
#client_pop_pc_lbl_pfp_dg {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#client_pop_pc_input_pfp_dh {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.client_pop_pc_input_pfp_dh {
    position: absolute;
    overflow: visible;
    width: 321.8px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#remark_grp_client_pop_pc {
    position: absolute;
    width: 321.579px;
    height: 107px;
    left: 59px;
    top: 592.504px;
    overflow: visible;
}
#remark_client_pop_pc_lbl {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#remark_client_pop_pc_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.remark_client_pop_pc_input {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 84px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#active_grp_client_pop_pc {
    position: absolute;
    width: 97.572px;
    height: 27px;
    left: 171px;
    top: 146.004px;
    overflow: visible;
}
#active_lbl_client_pop_pc {
    left: 43.572px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#active_chkbx_client_pop_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.active_chkbx_client_pop_pc {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 20px;
    left: 0px;
    top: 4px;
}
#Add_pup_client_pop_pcgrp:hover {
    left: 168px;
    top: 736px;
}
#Add_pup_client_pop_pcgrp {
    position: absolute;
    width: 123px;
    height: 41px;
    left: 162px;
    top: 729px;
    overflow: visible;
    border-radius: 20px;
    transition: all .3s ease-out;
}
#addd_bdr_btn_client_pop_pc {
    fill: rgba(192,255,219,1);
}
.addd_bdr_btn_client_pop_pc {
    position: absolute;
    overflow: visible;
    width: 123px;
    height: 41px;
    left: 0px;
    top: 0px;
}
#Add_client_pop_pc_lbl {
    left: 43.914px;
    top: 7.28px;
    position: absolute;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#client_pop_pc_grp {
    position: absolute;
    width: 321.579px;
    height: 63px;
    left: 59px;
    top: 404.504px;
    overflow: visible;
}
#client_pop_pc_lbl_ {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#client_pop_pc_pup_op {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.client_pop_pc_pup_op {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#close_pup_op_client_pop_pc {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 365px;
    top: 38px;
    overflow: visible;
}