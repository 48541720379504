.mediaViewInfo {
    --web-view-name: top bar;
    --web-view-id: top_bar;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: top_bar;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#top_bar {
    position: fixed;
    z-index: 3;
    width: 87vw;
    height: 56px;
    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: top bar;
    --web-view-id: top_bar;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#add_btn_top_bar_mob {
    position: relative;
    width:36px;
    height: 36px;
    left: calc(84% + 15px);
    /* right: 22px; */
    top: 10px;
    overflow: visible;
}
#add_bg_top {
    fill: rgba(232,239,246,1);
}
.add_bg_top {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 54px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#plus {
    fill: rgba(0,0,0,1);
}
.plus {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 9px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
}
#search_place_inout_project_asi {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.search_place_inout_project_asi {
    position: absolute;
    overflow: visible;
    width: 84%;
    height: 38px;
    left: 4px;
    top: 9px;
    border-radius:  10px;
    padding-left: 10px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#search_img_prohect_asignment {
    position: absolute;
    width: 20px;
    height: 20px;
    left: calc(84% - 30px);
    top: 17px;
    overflow: visible;
}

