.mediaViewInfo {
    --web-view-name: add task page mob;
    --web-view-id: add_task_page_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: add_task_page_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#add_task_page_mob {
    position: absolute;
    width: 100vw;
    height: 387px;
    top: 100px;
    display: flex;
    justify-content: center;
    /* left: 50%;
    transform: translate(-50%, -50%); */
left: 10px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: add task page mob;
    --web-view-id: add_task_page_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#n_6282087_rm141-nunny-02b {
    position: absolute;
    width: 100%;
    height: 388px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#add_task_mob_grp {
    position: absolute;
    width: 90vw;
    left: 3vw;
    height: 289px;
    top: 48px;
    overflow: visible;
}
#task_drop_down_mob_task_page {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.task_drop_down_mob_task_page {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 36px;
    left: 0px;
    top: 62px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#date_calendermob_task {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.date_calendermob_task {
    position: absolute;
    overflow: visible;
    width: 178px;
    height: 36px;
    left: 61px;
    top: 1px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#calendar_timeline_img {
    position: absolute;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#status_mob_task_timeline_page {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.status_mob_task_timeline_page {
    position: absolute;
    overflow: visible;
    width: 75%;
    height: 36px;
    left: 71px;
    top: 181px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#add_btn_timeline_page_mob_task {
    position: absolute;
    width: 68px;
    height: 36px;
    left: 105px;
    top: 253px;
    overflow: visible;
    border-radius: 10px;
}
#add_bg_timeline_page_mob_task {
    fill: rgba(232,239,246,1);
}
.add_bg_timeline_page_mob_task {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 86px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#Icon_ionic-timeline_page_mob_t {
    fill: rgba(0,0,0,1);
}
.Icon_ionic-timeline_page_mob_t {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 8.891px;
    top: 9.91px;
    transform: matrix(1,0,0,1,0,0);
}
#add_timeline_page_mob_task {
    left: 35px;
    top: 9px;
    position: absolute;
    overflow: visible;
    width: 25px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#hours_imob_taskt_timeline_page {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.hours_imob_taskt_timeline_page {
    position: absolute;
    overflow: visible;
    width: 61px;
    height: 36px;
    left: 0px;
    top: 181px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#date_calender_mob_task {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.date_calender_mob_task {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 36px;
    left: 0px;
    top: 122px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Add_Timesheet_lbl_ {
    /* left: 120px; */
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0,0,0,1);
}
#close_add_task_page {
    position: absolute;
    width: 18px;
    height: 18px;
    /* left: 217px; */
    right: 20px;
    top: 5px;
    overflow: visible;
}