.mediaViewInfo {
    --web-view-name: small nav left project;
    --web-view-id: small_nav_left_project;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

:root {
    --web-view-ids: small_nav_left_project;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

#small_nav_left_project {
    z-index: 1;
    position: absolute;
    width: 150px;
height: 100vh;
    overflow: hidden;
    --web-view-name: small nav left project;
    --web-view-id: small_nav_left_project;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#Group_2 {
    position: absolute;
    width: 112px;
    
    /* height: 697px; */
height: 87vh;
    left: 30px;
    top: 33px;
    overflow: visible;
}

#side_nav_icon_border {
    fill: rgba(219, 242, 231, 1);
}



.side_nav_icon_border {
    position: absolute;
    overflow: visible;
    width: 112px;
    height: 697px;
    left: 0px;
    top: 0px;
}

#over_all_grp {
    position: absolute;
    width: 70px;
    height: 485px;
    left: 22px;
    top: 28px;
    overflow: visible;
    display: grid;
}

#side_logo_icon {
    position: relative;
    width: 70px;
    height: 70px;
    left: 0px;
    /* top: 0px; */
border-radius: 50%;
    overflow: hidden;
}

#dash_bdr_grp {
    position: relative;
    width: 43px;
    height: 43px;
    left: 11px;
    /* top: 145px; */
    overflow: visible;
}

/* #dash_bdr {
    fill: rgba(255,255,255,1);
}
.dash_bdr {
    position: absolute;
    overflow: visible;
    width: 43px;
    height: 43px;
    left: 0px;
    top: 0px;
} */
#dashboard_Icon_I {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 6px;
    overflow: visible;
}

#dash_bdr_grp_client {
    position: relative;
    width: 43px;
    height: 43px;
    left: 11px;
    /* top: 90px; */
    overflow: visible;
}
#dashboard_Icon_I_client {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 6px;
    overflow: visible;
}

#offering_grp {
    position: relative;
    width: 45px;
    height: 45px;
    left: 11px;
    /* top: 340px; */
    overflow: visible;
}

#offerings_icon_I {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 8px;
    top: 8px;
    overflow: visible;
}

#connection_grp {
    position: relative;
    width: 45px;
    height: 45px;
    left: 11px;
    /* top: 275px; */
    overflow: visible;
}
#my_connections_icon_I {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 8px;
    top: 8px;
    overflow: visible;
}
#connection_grp_client{
    position: relative;
    width: 45px;
    height: 45px;
    left: 11px;
    /* top: 275px; */
    overflow: visible;
}
#my_connections_icon_I_client {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 8px;
    top: 8px;
    overflow: visible;
}

#logout_grp {
    position: relative;
    width: 30px;
    height: 30px;
    left: 20px;
    /* top: 460px; */
    overflow: visible;
}

#logout_icon_I {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 0px;
    top: 15px;
    overflow: visible;
    cursor: pointer;
}

#com_grp {
    position: relative;
    width: 45px;
    height: 45px;
    left: 11px;
    /* top: 210px; */
    overflow: visible;
}
#community_icon_I {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 8px;
    top: 8px;

    overflow: visible;
}
#time_line_ {
    position: relative;
    width: 45px;
    height: 45px;
    left: 11px;
    /* top: 400px; */
    overflow: visible;
}
#time_line_icon {
    position: relative;
    width: 28px;
    height: 28px;
    left: 8px;
    top: 8px;
    overflow: visible;
}
.highlight {

    border: 0px solid rgb(247, 240, 240);
    /* background-color: rgba(219, 242, 231, 1); */
    border-radius: 7px;
}

.highlight:hover {
    background-color: white;

}

/* animation */
/* @keyframes slideInRight {
    from {
        transform: translate3d(5%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.slideInRight {
    animation-name: slideInRight;
    animation-duration: 1s;
} */

.slide-right{
    animation: slide-right 0.4s linear both;
  }
  @keyframes slide-right {
    0%{
        transform:translate(-100px)
    }
    100%{
        transform:translate(0px)
    }
    
  } 

