#task_bar_graph_chart{
    position: relative;
    width: 50px;
    margin-right: 40px;
    height:160px ;
    /* left: 436px; */
    /* top: 76px; */
    display: inline-block;
    overflow: visible;
}
#task_1_box_dash_mob {
    fill: rgba(186,106,255,1);
}
.task_1_box_dash_mob {
    position: absolute;
    overflow: visible;
    width: 17px;
    height: 150px;
    left: 1px;
    /* top: 12px; */
    bottom: 0px;
    rotate: 180deg;
}

#Task_Name1_1_dash_mob {
    transform: translate(-61px, -74px) matrix(1,0,0,1,-1.7388,154.2434) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 147.097px;
    height: 15.380126953125px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(255,255,255,1);
}
/* #blue_1_client_dash_mob {
    fill: rgba(51,153,255,1);
} */
.blue_1_client_dash_mob {
    position: absolute;
    overflow: visible;
    width: 17px;
    height: 150px;
    left: 18px;
    /* top: 12px; */
    bottom: 0px;
    rotate: 180deg;
}
#n__blue_client_dash_mob {
    left: 18px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 18px;
    height: 12px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    color: rgba(0,0,0,1);
}
#n__purple_1_client_dash_mob {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 18px;
    height: 12px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    color: rgba(0,0,0,1);
}
