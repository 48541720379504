#task_page__card_1_mob_1 {
    position: relative;
    width: 174px;
    height: 182px;
    /* left: 5px; */
    top: 10px;
    overflow: visible;
}
#card_over_all_bg_task_page_mob {
    fill: transparent;
}
.card_over_all_bg_task_page_mob {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 182px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#task_name_bg_card_1_task_page_ {
    position: absolute;
    width: 174px;
    height: 49px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#developemtn_img_task_page_mob_ {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 57px;
    overflow: visible;
}
#development_lbl_task_page_mob_ {
    left: 33px;
    top: 59px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#task_name_task_page_mob_1 {
    left: 0px;
    top: 16px;
    position: absolute;
    overflow: visible;
    width: 175px;
    height: 17px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#img_22_task_page_mob_1 {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 87px;
    overflow: visible;
}
#n_2_hrs_lbltaskmob_1 {
    left: 33px;
    top: 89px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#time_2_img_task_mob_1 {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 97px;
    top: 87px;
    overflow: visible;
}
#time_2_task_page_mob_1 {
    left: 119px;
    top: 89px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
/* #work_in_progress_bdr_task_page {
    fill: rgba(119,238,170,1);
} */
.work_in_progress_bdr_task_page_mob {
    position: absolute;
    overflow: visible;
    width: 132px;
    height: 21px;
    left: 21px;
    top: 150px;
}
#work_in_progress_lbl_task_page {
    left: 38px;
    top: 152px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#assigned_to_pfp_task_page_mob_ {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 117px;
    overflow: visible;
}
#assigned_to_lbl_task_page__mob {
    left: 33px;
    top: 119px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}