 .mediaViewInfo {
    --web-view-name: Client page;
    --web-view-id: Client_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Client_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#Client_page {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: Client page;
    --web-view-id: Client_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
} 
#over_all_bg_client_page {
    position: absolute;
    width: 100vw;
    height: 100%;
    /* left: 172px; */
    top: 0px;
    /* overflow: visible; */
}

#add_bg_client_page {
    fill: rgba(232,239,246,1);
}
.add_bg_client_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 129px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#Icon_ionic-cleintpage {
    fill: rgba(0,0,0,1);
}
.Icon_ionic-cleintpage {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 9.5px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
}
#add_client_lbl_page {
    left: 35px;
    top: 9px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}

#client_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100vw - 180px);
    height: 486px;
    left: 172px;
    top: 88px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#client_scroll::-webkit-scrollbar{
    display: none;
}
#user_card_client_page {
    position: relative;
    margin: 7px;
    width: 174px;
    height: 140px;
    left: 8px;
    top: 14px;
    overflow: visible;
}
#card_over_all_bg_client_page {
    fill: transparent;
}
.card_over_all_bg_client_page {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 140px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#card_bg_design_client_page {
    position: absolute;
    width: 174px;
    height: 49px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#pfp_grp_client_page {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 64px;
    top: 25px;
    overflow: visible;
}
#pfp_behind_circle_client_page {
    fill: rgba(119,238,170,1);
}
.pfp_behind_circle_client_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 63px;
    left: 0px;
    top: 0px;
}
#profile_pic_card_client_page {
    position: absolute;
    width: 41px;
    height: 41px;
    left: 2px;
    top: 2px;
    overflow: hidden;
    border-radius: 50%;
}
#manager_lbl_client_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 101px;
    overflow: visible;
}
#Admin_lbl_client_page {
    left: 36px;
    top: 103px;
    position: absolute;
    overflow: visible;
    width: 139px;
    height: 37px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Mohammed_Affan_lbl_client_page {
    left: 0px;
    top: 74px;
    position: absolute;
    overflow: visible;
    width: 175px;
    height: 17px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}


