.mediaViewInfo {
    --web-view-name: client dashboard;
    --web-view-id: client_dashboard;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: client_dashboard;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#client_dashboard {
    position: absolute;
    width:100vw ;
    height: 100vh;
    /* min-width: 1000px; */
    /* left: 70px; */
    /* background-color: rgba(255,255,255,1); */
    overflow: visible;
    --web-view-name: client dashboard;
    --web-view-id: client_dashboard;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_bg_project_page_clien {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#pie_chart_client_dashboard {
    position: absolute;
    width: 180px;
    height: 180px;
    /* right: 120px; */
    left: 488px;
    top: 410px;
    /* bottom: 40px; */
    overflow: visible;
    font-size: 10px;
    font-family: 'Segoe UI';
    /* color: white; */
}
#overall_dashboard{
    left: 670px;
}
#bar_chart_grp_client_dashboard {
    position: absolute;
    width: calc(100% - 510px);
    /* min-width: 800px; */
    height: 245px;
    /* left: 504px; */
    display: inline-flex;
    overflow-x: auto;
    overflow-y: hidden;
    right: 30px;
    top: 84px;
    /* bottom: 250px; */
   
}
::-webkit-scrollbar {
    height: 2px;
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(255, 255, 255, 0); 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:rgba(219,242,231,1); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background:rgba(219,242,231,1);
}




#bar_chart_behind_bdr {
    fill: rgba(255,255,255,1);
}
.bar_chart_behind_bdr {
    filter: drop-shadow(5px 0px 50px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    /* width: 934px; */
    height: 354px;
    left: 0px;
    top: 0px;
}
#show_status_count{
    position: fixed;
    /* width: calc(100% - 510px); */
    /* height: 291px; */
    /* left: 504px; */
    /* display: inline-flex; */
    overflow: visible;

    /* right: 50px; */
    /* top: 88px; */
    
}
.show_status_count{
    filter: drop-shadow(5px 0px 50px rgba(0, 0, 0, 0.161));
    position: fixed;
    overflow: visible;
    /* width: 934px; */
    /* height: 354px; */
    left: 0px;
    top: 0px;
}

#add_task_bar_graph {
    fill: rgba(232,239,246,1);
}
.add_task_bar_graph {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: fixed;
    overflow: visible;
    width: 38px;
    height: 38px;
    /* left: 753px; */
    top: 87px;
    right: 68px;
}
#task_add_chat_bar {
    fill: rgba(0,0,0,1);
}
.task_add_chat_bar {
    overflow: visible;
    position: fixed;
    width: 15px;
    height: 15px;
    /* left: 756px; */
    right: 88px;
    top: 90px;
    transform: matrix(1,0,0,1,0,0);
}
#Tasks_lbl_chart_bar {
    /* left: 704px; */
    right: 120px;
    top: 87px;
    position: fixed;
    overflow: visible;
    width: 40px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}

#project_place_overall_bdr_grp {
    position: absolute;
    width: 300px;
    height: calc(100vh - 137px);
    left: 153px;
    top: 88px;
    overflow: visible;
}
#project_left_bdr_over_all {
    fill: rgba(255,255,255,1);
}
.project_left_bdr_over_all {
    filter: drop-shadow(0px 10px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 307px;
    height: 100%;
    left: 0px;
    top: 0px;
}
#Projects_lbl_client_dashboard {
    left: 110px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 60px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Scroll_project_client_dashboar {
    mix-blend-mode: normal;
    position: absolute;
    padding: 13px;
    width: 100%;
    height:93% ;
    left: 6px;
    top: 32px;
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Scroll_project_client_dashboar::-webkit-scrollbar{
    display: none;
}
#card_bdr_client_dashboard {
    fill: rgba(255,255,255,1);
}
.card_bdr_client_dashboard {
    filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
    position: relative;
    overflow: visible;
    width: 264px;
    height: 60px;
    left: 8px;
    top: 5px;
}
#profile_pfp_img_client_dashboa {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 17px;
    top: 11px;
    overflow: hidden;
    box-shadow:   box-shadow;
    background:  #e0e0e0;
    border-radius: 50%;
}
#Project_Name_client_dashboard {
    left: 69px;
    top: 9px;
    position: absolute;
    overflow: visible;
    width: 193px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Start_time_projpage_client_das {
    left: 65px;
    top: 31px;
    position: absolute;
    overflow: visible;
    width: 81px;
    height: 17px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#date_proj_page_client_dashboar {
    left: 154px;
    top: 31px;
    position: absolute;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#project_add_bdr_ {
    fill: rgba(232,239,246,1);
}
.project_add_bdr_ {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 181px;
    top: 1px;
}
#project_add_path_ {
    fill: rgba(0,0,0,1);
}
.project_add_path_ {
    overflow: visible;
    position: absolute;
    width: 15px;
    height: 15px;
    left: 184px;
    top: 4px;
    transform: matrix(1,0,0,1,0,0);
}
#assigned_staff_grp_btn_grp {
    position: absolute;
    width: 100px;
    height: 22px;
    /* left: 1071px; */
    right: 58px;
    top: 392px;
    overflow: visible;
}
#Assigned_Staff_lbl_client_dash {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_plus_bdr_assigned_staaff {
    fill: rgba(232,239,246,1);
}
.add_plus_bdr_assigned_staaff {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 56px;
    top: 1px;
}
#add_assigned_staff_plus {
    fill: rgba(0,0,0,1);
}
.add_assigned_staff_plus {
    overflow: visible;
    position: absolute;
    width: 15px;
    height: 15px;
    left: 59px;
    top: 4px;
    transform: matrix(1,0,0,1,0,0);
}




#Total_Tasks_-_100 {
    left: 526px;
    top: 380px;
    position: absolute;
    overflow: visible;
    width: 125px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}

#Scroll_staff_assigned_ {
		mix-blend-mode: normal;
		position: absolute;
		width: calc(100vw - 730px);
		/* height: 286px; */
		/* left: 833px; */
        right: 0px;
		top: 428px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_staff_assigned_::-webkit-scrollbar{
		display: none;
	}
    #card_1_assign_staff_grp {
		position: relative;
		width: 285px;
		height: 84px;
		left: 12px;
		top: 11px;
		overflow: visible;
        display: inline-block;

        margin: 5px;
	}
	#card_overall_bdr_assign_ {
		fill: transparent;
	}
	.card_overall_bdr_assign_ {
		position: absolute;
		overflow: visible;
		width: 285px;
		height: 84px;
		left: 0px;
		top: 0px;
		background:  rgba( 255, 255, 255, 0.5 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 10px );
		-webkit-backdrop-filter:  blur( 10px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#overall_pfp_bdr_assign_card {
		position: absolute;
		width: 86px;
		height: 84px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#pfp_bdr_assign_card {
		fill: rgba(255,255,255,1);
	}
	.pfp_bdr_assign_card {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 92px;
		height: 92px;
		left: 6px;
		top: 5px;
	}
	#profile_pic_img_assign_card {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 11px;
		top: 10px;
		overflow: hidden;
        border-radius: 50%;
	}
	#Mohammed_Affan_lbl_assign_card {
		left: 86px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 200px;
		height: 20px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(4,4,4,1);
	}
	#start_end_bdr_assign {
		fill: rgba(119,238,170,1);
	}
	.start_end_bdr_assign {
		position: absolute;
		overflow: visible;
		width: 174px;
		height: 23px;
		left: 101px;
		top: 40px;
	}
	#start_end_date_assign_card {
		left: 109px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 160px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#orange_bdr_assigned {
		fill: rgba(255,204,0,1);
	}
	.orange_bdr_assigned {
		position: absolute;
		overflow: visible;
		width: 17px;
		height: 17px;
		left: 102px;
		top: 67px;
	}
	#blue_bdr_assigned {
		fill: rgba(51,153,255,1);
	}
	.blue_bdr_assigned {
		position: absolute;
		overflow: visible;
		width: 17px;
		height: 17px;
		left: 141px;
		top: 67px;
	}
	#yellow_bdr_assigned {
		fill: rgba(255,153,0,1);
	}
	.yellow_bdr_assigned {
		position: absolute;
		overflow: visible;
		width: 17px;
		height: 17px;
		left: 177px;
		top: 67px;
	}
	#green_bdr_assigned {
		fill: rgba(0,204,102,1);
	}
	.green_bdr_assigned {
		position: absolute;
		overflow: visible;
		width: 17px;
		height: 17px;
		left: 216px;
		top: 67px;
	}
	#red_bdr_assigned {
		fill: rgba(255,0,0,1);
	}
	.red_bdr_assigned {
		position: absolute;
		overflow: visible;
		width: 17px;
		height: 17px;
		left: 255px;
		top: 67px;
	}
	#yellow_11_assigned {
		left: 104px;
		top: 67px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#blue_11_assigned {
		left: 143px;
		top: 67px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#orange_11_assigned {
		left: 179px;
		top: 67px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#green_11_assigned {
		left: 218px;
		top: 67px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#red_11_assigned {
		left: 257px;
		top: 67px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#Mohammed_Affan_lbl_assign_card_dn {
		left: 86px;
		top: 23px;
		position: absolute;
		overflow: visible;
		width: 200px;
		height: 17px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
    #status_grp_eye_pc {
		position: absolute;
		width: calc(100vw - 513px);
		height: 40px;
		left: 510px;
        display: inline-flex;
		/* top: 25px; */
		overflow: visible;
        /* z-index: 10; */
        top: 335px;
	}
	#unasigned_grp_pc {
		position: relative;
		width: calc(100% - 84%);
        min-width: 100px;

		height: 40px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Unassigned_lbl_client_dashboar {
		left: 21.5px;
		top: 23px;
		position: absolute;
		overflow: visible;
		width: 68px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#unassigned_grey {
		fill: rgba(153,153,153,1);
	}
	.unassigned_grey {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 0px;
		top: 25px;
	}
	#eye_unassigned {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 45.154px;
		top: 0px;
		overflow: visible;
		cursor: pointer;
	}
	#assigned_grp_pc {
		position: relative;
		width:  calc(100% - 87%);
        min-width: 100px;

		height: 40px;
		/* left: 123.957px; */
		top: 0px;
		overflow: visible;
	}
	#Assigned_lbl_client_dashboard {
		left: 22px;
		top: 23px;
		position: absolute;
		overflow: visible;
		width: 53px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#yellow_assigned_client_dashboa {
		fill: rgba(255,204,0,1);
	}
	.yellow_assigned_client_dashboa {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 0px;
		top: 25px;
	}
	#eye_assigned {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 38.197px;
		top: 0px;
		overflow: visible;
		cursor: pointer;

	}
	#work_in_progress_grp_pc {
		position: relative;
		width: calc(100% - 82%);
        min-width: 140px;

		height: 40px;
		/* left: 229.694px; */
		top: 0px;
		overflow: visible;
	}
	#Work_in_progress_client_dashbo {
		left: 21.007px;
		top: 23px;
		position: absolute;
		overflow: visible;
		width: 99px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#blue_work_in_progress {
		fill: rgba(51,153,255,1);
	}
	.blue_work_in_progress {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 0px;
		top: 25px;
	}
	#eye_work_in_progress {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 60.46px;
		top: 0px;
		overflow: visible;
		cursor: pointer;

	}
	#on_hold_grp_pc {
		position: relative;
		width: calc(100% - 85%);
        min-width: 100px;

		height: 40px;
		/* left: 381.769px; */
		top: 0px;
		overflow: visible;
	}
	#On_Hold_lbl_client_dashboard {
		left: 21.615px;
		top: 23px;
		position: absolute;
		overflow: visible;
		width: 49px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#orange_on_hold {
		fill: rgba(255,153,0,1);
	}
	.orange_on_hold {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 0px;
		top: 25px;
	}
	#eye_on_hold {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 40.386px;
		top: 0px;
		overflow: visible;
		cursor: pointer;

	}
	#completed_grp_pc {
		position: relative;
		width:calc(100% - 85%);
        min-width: 100px;
		height: 40px;
		/* left: 482.668px; */
		top: 0px;
		overflow: visible;
	}
	#Completed_lbl_client_dashboard {
		left: 21.144px;
		top: 23px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#completed_greeen {
		fill: rgba(0,204,102,1);
	}
	.completed_greeen {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 0px;
		top: 25px;
	}
	#eye_completed {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 43.486px;
		top: 0px;
		overflow: visible;
		cursor: pointer;

	}
	#cancelled_grp_pc {
		position: relative;
		width: calc(100% - 87%);
        min-width: 100px;

		height: 40px;
		/* left: 601px; */
		top: 0px;
		overflow: visible;
	}
	#Cancelled_lbl_client_dashboard {
		left: 21.309px;
		top: 23px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#red_cancelled_client_dashboard {
		fill: rgba(255,0,0,1);
	}
	.red_cancelled_client_dashboard {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 0px;
		top: 25px;
	}
	#eye_cancelled {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 39.154px;
		top: 0px;
		overflow: visible;
		cursor: pointer;

	}