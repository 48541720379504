.mediaViewInfo {
    --web-view-name: task page;
    --web-view-id: task_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: task_page;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#task_page {
    position: absolute;
    width: 100vw;
    height: 100vh;
    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: task page;
    --web-view-id: task_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_bg_project_page {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#right_grp_task_page {
    position: relative;
    width: 180px;
    height: 100vh;
    left: 1100px;
    top: 20px;
    overflow: visible;
}
#pen_1 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 1020px;
    top: 89px;
    overflow: visible;
}

#categories_overall_card {
    position: relative;
    width: 197px;
    /* height: calc(calc(100vw/3) - 100px); */
    height: 35%;
    min-height: 150px;
   right: 0px;
    /* top: 30px; */
    margin-bottom: 10px;
    overflow: visible;
}
#grpTask_page {
    position: absolute;
    width: 197px;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#bgTask_page {
    fill: transparent;
}
.bgTask_page {
    position: absolute;
    overflow: visible;
    width: 197px;
    height: calc(100% - 7px);
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 20px );
    -webkit-backdrop-filter:  blur( 20px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#categories_Task_page {
    left: 68px;
    top: 24px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Icon_ionic-minus_Task_page {
    fill: rgba(0,0,0,1);
}
.Icon_ionic-minus_Task_page {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 164.5px;
    top: 24px;
    transform: matrix(1,0,0,1,0,0);
    cursor: pointer;
}
#documentation_grp_category {
    position: relative;
    width: 167px;
    height: 20px;
    left: 16px;
    /* top: 63px; */
    overflow: visible;
}
#add_minus_Task_page {
    left: 0px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 90px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#minus_Task_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 147px;
    top: 0px;
    overflow: visible;
}
#add_bg_minus_Task_page {
    fill: rgba(232,239,246,1);
}
.add_bg_minus_Task_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#Icon_ionic-minus_Task_page_cd {
    fill: rgba(255,55,55,1);
    stroke: rgba(255,55,55,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Icon_ionic-minus_Task_page_cd {
    overflow: visible;
    position: absolute;
    width: 11.193px;
    height: 2.359px;
    left: 5.217px;
    top: 9.634px;
    transform: matrix(1,0,0,1,0,0);
}
#edit_grp_Task_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 119px;
    top: 0px;
    overflow: visible;
}
#add_bg_edit_grp {
    fill: rgba(232,239,246,1);
}
.add_bg_edit_grp {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#pencil_1_edit_grp {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#input_categories_grp {
    position: relative;
    width: 168px;
    height: 22px;
    left: 15px;
    top: 5px;
    overflow: visible;
}
#cancel_grp_Task_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 148px;
    top: 1px;
    overflow: visible;
}
#add_bgcancel {
    fill: rgba(232,239,246,1);
}
.add_bgcancel {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#Icon_ioniccancel {
    fill: rgba(255,55,55,1);
}
.Icon_ioniccancel {
    overflow: visible;
    position: absolute;
    width: 14.056px;
    height: 14.056px;
    transform: translate(-1089px, -93px) matrix(1,0,0,1,1091.9718,95.9718) rotate(45deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#save_grp_bdr_Task_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 120px;
    top: 1px;
    overflow: visible;
}
#save_bdr_Task_page {
    fill: rgba(232,239,246,1);
}
.save_bdr_Task_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#diskette_2_Task_page {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#edit_documentation {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.edit_documentation {
    position: absolute;
    overflow: visible;
    width: 107px;
    height: 22px;
    left: 0px;
    top: 0px;
    border-radius:  10px;
    padding-left: 10px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#Modules_over_all_grp_task_page {
    position: relative;
    width: 197px;
    height: 34%;
    min-height: 150px;
    right: 0px;
    margin-bottom: 10px;
    /* top: 220px; */
    overflow: visible;
}
#module_ {
    position: absolute;
    width: 197px;
    height:100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#bdr_module_Task_page {
    fill: transparent;
}
.bdr_module_Task_page {
    position: absolute;
    overflow: visible;
    width: 197px;
    height:calc(100% - 7px);
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 20px );
    -webkit-backdrop-filter:  blur( 20px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#add_btn_Task_page {
    fill: rgba(0,0,0,1);
}
.add_btn_Task_page {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 161.5px;
    top: 24px;
    transform: matrix(1,0,0,1,0,0);
    cursor: pointer;
}
#module_lbl_Task_page {
    left: 74px;
    top: 24px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#documentation_grp_module {
    position: relative;
    width: 167px;
    height: 20px;
    left: 16px;
    overflow: visible;
}
#documentation_lbl_ {
    left: 0px;
    top: 2px;
    position: relative;
    overflow: visible;
    width: 90px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#minus_grp {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 147px;
    top: 0px;
    overflow: visible;
}
#bdr_minus_Task_page {
    fill: rgba(232,239,246,1);
}
.bdr_minus_Task_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#minus_path_ {
    fill: rgba(255,55,55,1);
    stroke: rgba(255,55,55,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.minus_path_ {
    overflow: visible;
    position: absolute;
    width: 11.193px;
    height: 2.359px;
    left: 5.217px;
    top: 9.634px;
    transform: matrix(1,0,0,1,0,0);
}
#edit_btn_grp {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 119px;
    top: 0px;
    overflow: visible;
}
#bdr_edit_option {
    fill: rgba(232,239,246,1);
}
.bdr_edit_option {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#pencil_1_Task_page {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#input_grp_modules {
    position: relative;
    width: 168px;
    height: 22px;
    left: 15px;
    top: 5px;
    overflow: visible;
}
#cancel_btn_grp {
    position: relative;
    width: 20px;
    height: 20px;
    left: 148px;
    top: 1px;
    overflow: visible;
}
#add_bg_client_page {
    fill: rgba(232,239,246,1);
}
.add_bg_client_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#Icon_ionic-cleintpage {
    fill: rgba(255,55,55,1);
}
.Icon_ionic-cleintpage {
    overflow: visible;
    position: absolute;
    width: 14.056px;
    height: 14.056px;
    transform: translate(-1089px, -93px) matrix(1,0,0,1,1091.9718,95.9718) rotate(45deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#save_btn_grp_ {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 120px;
    top: 1px;
    overflow: visible;
}
#bdr_save_btn_ {
    fill: rgba(232,239,246,1);
}
.bdr_save_btn_ {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 0px;
    top: 0px;
}
#diskette_2 {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#text_box_module_task_page {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.text_box_module_task_page {
    position: absolute;
    overflow: visible;
    width: 107px;
    height: 22px;
    left: 0px;
    top: 0px;
    border-radius:  10px;
    padding-left: 10px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#back_btn {
    position: absolute;
    cursor: pointer;
    top: 85px;
    right: 250px;
    width: 40px;
    height: 39px;
    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: back btn;
    --web-view-id: back_btn;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#titles_and_back_btn_grp {
    position: absolute;
    width: 138px;
    height: 39px;
    left: 135px;
    top: 90px;
    overflow: visible;
}
#unassigned {
    left: 55px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 84px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(4,4,4,1);
}
#unassigned_b {
    left: 55px;
    top: 22px;
    position: absolute;
    overflow: visible;
    width: 71px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#right-arrow_2_back_btn_task {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 0px;
    top: 9px;
    overflow: visible;
}
#all_status_scroll {
    mix-blend-mode: normal;
    position: relative;
    width: calc(100% - 400px);
    height: 476px;
    left: 165px;
    top: 122px;
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
}
#all_status_scroll::-webkit-scrollbar{
    display: none;
}
body::-webkit-scrollbar{
    display: none;
}
#unassigned_status_drop_grp {
    position: relative;
    width: 100%;
    height: auto;
    display:block;
    min-height: 20px;
    left: 22px;
    /* top: 15px; */
    overflow: visible;
}
#caard_1_status_drop_tax_page_s {
    mix-blend-mode: normal;
    position: relative;
    width: 174px;
    height: 205px;
 display: inline-block;

    margin: 10px;
    /* overflow-x: hidden;
    overflow-y: hidden; */
}
/* #task_page__card_1 {
    position: absolute;
    width: 174px;
    height: 182px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#card_over_all_bg_task_page {
    fill: transparent;
}
.card_over_all_bg_task_page {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 182px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#task_name_bg_card_1_task_page {
    position: absolute;
    width: 174px;
    height: 49px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#developemtn_img_task_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 57px;
    overflow: visible;
}
#development_lbl_task_page {
    left: 33px;
    top: 59px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#task_name_task_page {
    left: 0px;
    top: 16px;
    position: absolute;
    overflow: visible;
    width: 175px;
    height: 17px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#img_22_task_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 87px;
    overflow: visible;
}
#n_2_hrs_lbltask_page {
    left: 33px;
    top: 89px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#time_2_img_task_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 97px;
    top: 87px;
    overflow: visible;
}
#time_2_task_page {
    left: 119px;
    top: 89px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
} */
/* #work_in_progress_bdr_task_page {
    fill: rgba(119,238,170,1);
} */
/* .work_in_progress_bdr_task_page {
    position: absolute;
    overflow: visible;
    width: 132px;
    height: 21px;
    left: 21px;
    top: 150px;
} */
/* #work_in_progress_lbl_task_page {
    left: 38px;
    top: 152px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
} */
#assigned_to_pfp_task_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 117px;
    overflow: visible;
}
#assigned_to_lbl_task_page {
    left: 33px;
    top: 119px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#top_status_area {
    position: absolute;
    width: 886px;
    height: 18px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#unassigned_lbl_ {
    left: 43.5px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 71px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#right-arrow_2_drop_btn {
    position: absolute;
    cursor: pointer;
    width: 18px;
    height: 18px;
    left: 19.5px;
    top: 0px;
    overflow: visible;
}
#long_line_unassigned {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.long_line_unassigned {
    overflow: visible;
    position: absolute;
    width: 761px;
    height: 1px;
    left: 125px;
    top: 7.5px;
    transform: matrix(1,0,0,1,0,0);
}
#left_arrow_line_ {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.left_arrow_line_ {
    overflow: visible;
    position: absolute;
    width: 14px;
    height: 1px;
    left: 0px;
    top: 9.5px;
    transform: matrix(1,0,0,1,0,0);
}
#assigned_status_drop_grp {
    position: relative;
    width: 100%;
    height: auto;
    display:block;
    min-height: 20px;
    left: 22px;
    /* top: 259px; */
    overflow: visible;
}
#top_status_area_assigned_ {
    position:absolute ;
    width: 886px;
    height: 18px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#assigned_lbl_ {
    left: 43.5px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#right-arrow_2_dassigned_ {
    position: absolute;
    cursor: pointer;
    width: 18px;
    height: 18px;
    left: 19.5px;
    top: 0px;
    overflow: visible;
}
#long_line_assigned_ {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.long_line_assigned_ {
    overflow: visible;
    position: absolute;
    width: 761px;
    height: 1px;
    left: 125px;
    top: 7.5px;
    transform: matrix(1,0,0,1,0,0);
}
#left_arrow_line_assigned_ {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.left_arrow_line_assigned_ {
    overflow: visible;
    position: absolute;
    width: 14px;
    height: 1px;
    left: 0px;
    top: 9.5px;
    transform: matrix(1,0,0,1,0,0);
}
#caard_1_status_drop_tax_page_s_ef {
    mix-blend-mode: normal;
    position: absolute;
    width: 865px;
    height: 205px;
    left: 0px;
    top: 29px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#task_page__card_1_eg {
    position: absolute;
    width: 174px;
    height: 182px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#card_over_all_bg_task_page_eh {
    fill: transparent;
}
.card_over_all_bg_task_page_eh {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 182px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#task_name_bg_card_1_task_page_ei {
    position: absolute;
    width: 174px;
    height: 49px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#developemtn_img_task_page_ej {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 57px;
    overflow: visible;
}
#development_lbl_task_page_ek {
    left: 33px;
    top: 59px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#task_name_task_page_el {
    left: 0px;
    top: 16px;
    position: absolute;
    overflow: visible;
    width: 175px;
    height: 17px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#img_22_task_page_em {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 87px;
    overflow: visible;
}
#n_2_hrs_lbltask_page_en {
    left: 33px;
    top: 89px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#time_2_img_task_page_eo {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 97px;
    top: 87px;
    overflow: visible;
}
#time_2_task_page_ep {
    left: 119px;
    top: 89px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#work_in_progress_bdr_task_page_eq {
    fill: rgba(119,238,170,1);
}
.work_in_progress_bdr_task_page_eq {
    position: absolute;
    overflow: visible;
    width: 132px;
    height: 21px;
    left: 21px;
    top: 150px;
}
#work_in_progress_lbl_task_page_er {
    left: 38px;
    top: 152px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#assigned_to_pfp_task_page_es {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 117px;
    overflow: visible;
}
#assigned_to_lbl_task_page_et {
    left: 33px;
    top: 119px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#work_in_progress_status_drop_g {
    position: relative;
    width: 100%;
    height: auto;
    left: 22px;
    display: block;
    min-height: 20px;
    /* top: 515.5px; */
    overflow: visible;
}
#top_status_area_work_in_progre {
    position: absolute;
    width: 886px;
    height: 18px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#work_in_progresslbl_ {
    left: 43.5px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 106px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#right-arrow_2_dwork_in_progres {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 19.5px;
    top: 0px;
    overflow: visible;
}
#long_line_work_in_progress {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.long_line_work_in_progress {
    overflow: visible;
    position: absolute;
    width: 729px;
    height: 1px;
    left: 157px;
    top: 7.625px;
    transform: matrix(1,0,0,1,0,0);
}
#left_arrow_line__work_in_progr {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.left_arrow_line__work_in_progr {
    overflow: visible;
    position: absolute;
    width: 14px;
    height: 1px;
    left: 0px;
    top: 9.625px;
    transform: matrix(1,0,0,1,0,0);
}
#caard_1_status_drop_tax_page_s_e {
    mix-blend-mode: normal;
    position: absolute;
    width: 865px;
    height: 205px;
    left: 0px;
    top: 29.5px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#task_page__card_1_e {
    position: absolute;
    width: 174px;
    height: 182px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#card_over_all_bg_task_page_e {
    fill: transparent;
}
.card_over_all_bg_task_page_e {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 182px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#task_name_bg_card_1_task_page_e {
    position: absolute;
    width: 174px;
    height: 49px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#developemtn_img_task_page_e {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 57px;
    overflow: visible;
}
#development_lbl_task_page_e {
    left: 33px;
    top: 59px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#task_name_task_page_e {
    left: 0px;
    top: 16px;
    position: absolute;
    overflow: visible;
    width: 175px;
    height: 17px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#img_22_task_page_e {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 87px;
    overflow: visible;
}
#n_2_hrs_lbltask_page_e {
    left: 33px;
    top: 89px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#time_2_img_task_page_e {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 97px;
    top: 87px;
    overflow: visible;
}
#time_2_task_page_fa {
    left: 119px;
    top: 89px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#work_in_progress_bdr_task_page_fb {
    fill: rgba(119,238,170,1);
}
.work_in_progress_bdr_task_page_fb {
    position: absolute;
    overflow: visible;
    width: 132px;
    height: 21px;
    left: 21px;
    top: 150px;
}
#work_in_progress_lbl_task_page_fc {
    left: 38px;
    top: 152px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#assigned_to_pfp_task_page_fd {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 117px;
    overflow: visible;
}
#assigned_to_lbl_task_page_fe {
    left: 33px;
    top: 119px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#on_hold_status_drop_grp {
    position: relative;
    width: 100%;
    height: auto;
    display:block;
    min-height: 20px;
    left: 22px;
    /* top: 759.244px; */
    overflow: visible;
}
#top_status_area_completed_ {
    position: absolute;
    width: 886px;
    height: 18px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#completed_lbl_ {
    left: 43.5px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#right-arrow_2_drop_btn_complet {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 19.5px;
    top: 0px;
    overflow: visible;
}
#long_line_completed_ {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.long_line_completed_ {
    overflow: visible;
    position: absolute;
    width: 762px;
    height: 1px;
    left: 124px;
    top: 7.75px;
    transform: matrix(1,0,0,1,0,0);
}
#left_arrow_line_completed_ {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.left_arrow_line_completed_ {
    overflow: visible;
    position: absolute;
    width: 14px;
    height: 1px;
    left: 0px;
    top: 9.75px;
    transform: matrix(1,0,0,1,0,0);
}
#caard_1_status_drop_tax_page_s_fl {
    mix-blend-mode: normal;
    position: absolute;
    width: 865px;
    height: 205px;
    left: 0px;
    top: 29.756px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#task_page__card_1_fm {
    position: absolute;
    width: 174px;
    height: 182px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#card_over_all_bg_task_page_fn {
    fill: transparent;
}
.card_over_all_bg_task_page_fn {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 182px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#task_name_bg_card_1_task_page_fo {
    position: absolute;
    width: 174px;
    height: 49px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#developemtn_img_task_page_fp {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 57px;
    overflow: visible;
}
#development_lbl_task_page_fq {
    left: 33px;
    top: 59px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#task_name_task_page_fr {
    left: 0px;
    top: 16px;
    position: absolute;
    overflow: visible;
    width: 175px;
    height: 17px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#img_22_task_page_fs {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 87px;
    overflow: visible;
}
#n_2_hrs_lbltask_page_ft {
    left: 33px;
    top: 89px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#time_2_img_task_page_fu {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 97px;
    top: 87px;
    overflow: visible;
}
#time_2_task_page_fv {
    left: 119px;
    top: 89px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#work_in_progress_bdr_task_page_fw {
    fill: rgba(119,238,170,1);
}
.work_in_progress_bdr_task_page_fw {
    position: absolute;
    overflow: visible;
    width: 132px;
    height: 21px;
    left: 21px;
    top: 150px;
}
#work_in_progress_lbl_task_page_fx {
    left: 38px;
    top: 152px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#assigned_to_pfp_task_page_fy {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 117px;
    overflow: visible;
}
#assigned_to_lbl_task_page_fz {
    left: 33px;
    top: 119px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#completed_status_drop_grp {
    position: relative;
    width: 100%;
    height: auto;
    display:block;
    min-height: 20px;
    left: 22px;
    /* top: 770.989px; */
    overflow: visible;
}
#top_status_area_completed__f {
    position: absolute;
    width: 100%;
    height: auto;
    display: inline-block;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#completed_lbl__f {
    left: 43.5px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#right-arrow_2_drop_btn_complet_f {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 19.5px;
    top: 0px;
    overflow: visible;
}
#long_line_completed__f {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.long_line_completed__f {
    overflow: visible;
    position: absolute;
    width: 762px;
    height: 1px;
    left: 124px;
    top: 7.875px;
    transform: matrix(1,0,0,1,0,0);
}
#left_arrow_line__completed_ {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.left_arrow_line__completed_ {
    overflow: visible;
    position: absolute;
    width: 14px;
    height: 1px;
    left: 0px;
    top: 9.875px;
    transform: matrix(1,0,0,1,0,0);
}
#caard_1completed__tax_page_sro {
    mix-blend-mode: normal;
    position: absolute;
    width: 865px;
    height: 205px;
    left: 0px;
    top: 27.011px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#task_page__card_1_completed_ {
    position: absolute;
    width: 174px;
    height: 182px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#card_over_all_bg_task_page_f {
    fill: transparent;
}
.card_over_all_bg_task_page_f {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 182px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#task_name_bg_card_1_task_page_f {
    position: absolute;
    width: 174px;
    height: 49px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#developemtn_img_task_page_ga {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 57px;
    overflow: visible;
}
#development_lbl_task_page_gb {
    left: 33px;
    top: 59px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#task_name_task_page_gc {
    left: 0px;
    top: 16px;
    position: absolute;
    overflow: visible;
    width: 175px;
    height: 17px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#img_22_task_page_gd {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 87px;
    overflow: visible;
}
#n_2_hrs_lbltask_page_ge {
    left: 33px;
    top: 89px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#time_2_img_task_page_gf {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 97px;
    top: 87px;
    overflow: visible;
}
#time_2_task_page_gg {
    left: 119px;
    top: 89px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#work_in_progress_bdr_task_page_gh {
    fill: rgba(119,238,170,1);
}
.work_in_progress_bdr_task_page_gh {
    position: absolute;
    overflow: visible;
    width: 132px;
    height: 21px;
    left: 21px;
    top: 150px;
}
#work_in_progress_lbl_task_page_gi {
    left: 38px;
    top: 152px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#assigned_to_pfp_task_page_gj {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 117px;
    overflow: visible;
}
#assigned_to_lbl_task_page_gk {
    left: 33px;
    top: 119px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#cancelled_status_drop_grp {
    position: relative;
    width: 100%;
    height: auto;
    display:block;
    min-height: 20px;
    left: 22px;
    /* top: 1025.472px; */
    overflow: visible;
}
#top_status_areacancelled {
    position: absolute;
    width: 886px;
    height: 18px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#cancelledlbl_ {
    left: 43.5px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 60px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#right-arrow_2_drop_btn_cancell {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 19.5px;
    top: 0px;
    overflow: visible;
}
#long_line_cancelled {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.long_line_cancelled {
    overflow: visible;
    position: absolute;
    width: 762px;
    height: 1px;
    left: 124px;
    top: 7.028px;
    transform: matrix(1,0,0,1,0,0);
}
#left_arrow_linecancelled_ {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.left_arrow_linecancelled_ {
    overflow: visible;
    position: absolute;
    width: 14px;
    height: 1px;
    left: 0px;
    top: 9.028px;
    transform: matrix(1,0,0,1,0,0);
}
#caard_1_status_drop_tax_pageca {
    mix-blend-mode: normal;
    position: absolute;
    width: 865px;
    height: 205px;
    left: 0px;
    top: 27.528px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#task_page__card_1_gs {
    position: absolute;
    width: 174px;
    height: 182px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#card_over_all_bg_task_page_gt {
    fill: transparent;
}
.card_over_all_bg_task_page_gt {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 182px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#task_name_bg_card_1_task_page_gu {
    position: absolute;
    width: 174px;
    height: 49px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#developemtn_img_task_page_gv {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 57px;
    overflow: visible;
}
#development_lbl_task_page_gw {
    left: 33px;
    top: 59px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#task_name_task_page_gx {
    left: 0px;
    top: 16px;
    position: absolute;
    overflow: visible;
    width: 175px;
    height: 17px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#img_22_task_page_gy {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 87px;
    overflow: visible;
}
#n_2_hrs_lbltask_page_gz {
    left: 33px;
    top: 89px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#time_2_img_task_page_g {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 97px;
    top: 87px;
    overflow: visible;
}
#time_2_task_page_g {
    left: 119px;
    top: 89px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#work_in_progress_bdr_task_page_g {
    fill: rgba(119,238,170,1);
}
.work_in_progress_bdr_task_page_g {
    position: absolute;
    overflow: visible;
    width: 132px;
    height: 21px;
    left: 21px;
    top: 150px;
}
#work_in_progress_lbl_task_page_g {
    left: 38px;
    top: 152px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#assigned_to_pfp_task_page_g {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 117px;
    overflow: visible;
}
#assigned_to_lbl_task_page_g {
    left: 33px;
    top: 119px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Modules_over_all_grp_task_page_eb {
    position: relative;
    width: 197px;
    height: 31%;
    min-height: 150px;
    right: 0px;
    /* margin-right: 20px; */
    /* top: 415px; */
    overflow: visible;
    /* margin-bottom: 20px; */

}
#team_area_grp {
    position: absolute;
    width: 197px;
    height:100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#bdr_module_Task_page_team {
    fill: transparent;
}
.bdr_module_Task_page_team {
    position: absolute;
    overflow: visible;
    width: 197px;
    height: calc(100% - 7px);
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 20px );
    -webkit-backdrop-filter:  blur( 20px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#add_btn_Task_page_team {
    fill: rgba(0,0,0,1);
}
.add_btn_Task_page_team {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 161.5px;
    top: 24px;
    transform: matrix(1,0,0,1,0,0);
}
#team_lbl_Task_page {
    left: 84px;
    top: 24px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Scroll_team_cards {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: calc(100% - 67px);
    left: 0px;
    top: 57px;
    overflow-y: scroll;
}
#Scroll_team_cards::-webkit-scrollbar{
    display: none;
}
#team_grp_module {
    position: relative;
    display: block;
    margin: 5px;
    width: calc(100% - 12px);
    height: 64px;
    left: 2px;
    top: 3px;
    overflow: visible;
}
#team_bdr_ {
    fill: rgba(255,255,255,1);
}
.team_bdr_ {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 64px;
    left: 0px;
    top: 0px;
}
#documentation_lbl_team_staff {
    left: 4px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#documentation_lbl_role_team {
    left: 4px;
    top: 22px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(4,4,4,1);
}
#documentation_lbl_date_team {
    left: 3px;
    top: 40px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(4,4,4,1);
}
#category_scroll{
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: calc(100% - 65px);
    left: 0px;
    top: 57px;
    overflow-y: scroll;
    overflow-x: hidden;

}
#category_scroll::-webkit-scrollbar{
    display: none;
}
#module_scroll{
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height:calc(100% - 65px);
    left: 0px;
    top: 57px;
    overflow-y: scroll;
    overflow-x: hidden;
}
#module_scroll::-webkit-scrollbar{
    display: none;
}
#category_module_team_grp{
position: absolute;
display: block ;
justify-content: center ;
top: 40px;
right: 10px;
height: calc(100vh - 85px);
/* border: 2px solid black; */
}