.mediaViewInfo {
    --web-view-name: project page mob;
    --web-view-id: project_page_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: project_page_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#project_page_mob {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: project page mob;
    --web-view-id: project_page_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_bg_design {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#project_page_mob_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 90%;
    left: 0px;
    top: 63px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#card_over_all_bg_project_page_ {
    fill: transparent;
}
#project_card_mob{
position: relative;
overflow: visible;
    width: 93vw;
    height: 103px;
    margin-top: 9px;
}
.card_over_all_bg_project_page_ {
    position: absolute;
    overflow: visible;
    width: 93vw;
    height: 103px;
    left: 12px;
    top: 8px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#card_bg_design_project_page_mo {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 12px;
    top: 9px;
    overflow: visible;
}
/* #pfp_behind_circle_project_page_mob {
    fill: rgba(255,255,255,1);
} */
.pfp_behind_circle_project_page_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 108px;
    height: 108px;
    left: 17.001px;
    top: 16px;
}
#profile_pic_card_project_page_ {
    position: absolute;
    width: 82px;
    height: 82px;
    left: 21px;
    top: 20px;
    overflow: hidden;
    border-radius: 50%;
}
#manager_lbl_project_page_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 137px;
    top: 31px;
    overflow: visible;
}
#Admin_lbl_project_page_mob {
    left: 164px;
    top: 33px;
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Mohammed_Affan_lbl_staff_page_ {
    left: 135px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 195px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Start_time_projpage_mob {
    left: 135px;
    top: 63px;
    position: absolute;
    overflow: visible;
    width: 81px;
    height: 17px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#date_proj_page_mob {
    left: 224px;
    top:63px;
    position: absolute;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#road_time_grp_mob {
    position: absolute;
    width: 165px;
    height: 34px;
    left: 120px;
    top: 77px;
    overflow: visible;
}
#road_bdr_mob {
    opacity: 0.75;
    fill: rgba(133,133,133,1);
}
.road_bdr_mob {
    position: absolute;
    overflow: visible;
    width: calc(100vw - 175px);
    height: 25px;
    left: 0px;
    top: 9px;
}
.proj_road_line_mob {
    overflow: visible;
    position: absolute;
    width:  calc(100vw - 190px);
    height: 1px;
    left: 5px;
    top: 21.5px;
    transform: matrix(1,0,0,1,0,0);
    border: 1px dashed white;
}
/* #proj_road_line_mob {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
    width:  calc(100vw - 190px);

} */

#proj_startpage_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0px;
    top: 2px;
    overflow: visible;
}
#finish_img_pro_page_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left:  calc(100vw - 190px);
    top: 0px;
    overflow: visible;
}