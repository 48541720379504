.mediaViewInfo {
    --web-view-name: time sheet bill card;
    --web-view-id: time_sheet_bill_card;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: time_sheet_bill_card;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#time_sheet_bill_card {
    position: relative;
    width: 182px;
    height: 189px;
    top: 5px;
    padding-right: 188px;
    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: time sheet bill card;
    --web-view-id: time_sheet_bill_card;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#billed_hrs_card_mob {
    position: absolute;
    width: 174px;
    height: 182px;
    left: 2px;
    top: 3px;
    overflow: visible;
}
#card_over_all_bg_task_page_mo__dh {
    fill: url(#card_over_all_bg_task_page_mo__dh);
}
.card_over_all_bg_task_page_mo__dh {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 182px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#total_build_hrs_timesheet {
    left: 43px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 89px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#Scroll_details_total_hrs_times {
    mix-blend-mode: normal;
    position: absolute;
    width: 174px;
    height: 145px;
    left: 0px;
    top: 33px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#affan_grp_timesheet {
    position: relative;
    width: 164px;
    height: 19px;
    left: 6px;
    top: 0px;
    overflow: visible;
    margin-top: 7px;
    /* display: block; */
}
#module_timeline_page_name {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 147px;
    height: 19px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(0,0,0,1);
}
#module_timeline_page_amount {
    left: 149px;
    top: 0px;
    position: relative;
    overflow: visible;
    width: 16px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(0,0,0,1);
}
#divider {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.divider {
    overflow: visible;
    position: absolute;
    width: 158px;
    height: 1px;
    left: 6.5px;
    top: 28.5px;
    transform: matrix(1,0,0,1,0,0);
}
