

#over_all_bg_page {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
   
   
}
#staff_page {
    position: absolute;
    width: 100vw;
    height: 100vh;
    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: staff page;
    --web-view-id: staff_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#staff_page_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100vw - 175px);
    height: 480px;
    left: 172px;
    top: 88px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#staff_page_scroll::-webkit-scrollbar{
    display: none;
}
#user_card_staff_page {
    position: relative;
    width: 174px;
    height: 227px;
    left: 20px;
    top: 14px;
    overflow: visible;
    margin: 7px;
}
#card_over_all_bg_staff_page {
    fill: transparent;
}
.card_over_all_bg_staff_page {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 227px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#card_bg_design_staff_page {
    position: absolute;
    width: 174px;
    height: 49px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#pfp_grp_staff_page {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 64px;
    top: 25px;
    overflow: visible;
}
#pfp_behind_circle_staff_page {
    fill: rgba(119,238,170,1);
}
.pfp_behind_circle_staff_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 63px;
    left: 0px;
    top: 0px;
}
#profile_pic_card_staff_page {
    position: absolute;
    width: 41px;
    height: 41px;
    left: 2px;
    top: 2px;
    overflow: hidden;
    border-radius: 50%;
}
#exp_staff_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 159px;
    overflow: visible;
}
#yr_staff_page {
    left: 37px;
    top: 161px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#iphone-staff_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 190px;
    overflow: visible;
}
#staff_page_mobile_number {
    left: 37px;
    top: 192px;
    position: absolute;
    overflow: visible;
    width: 71px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#manager_lbl_staff_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 101px;
    overflow: visible;
}
#Admin_lbl_staff_page {
    left: 37px;
    top: 103px;
    position: absolute;
    overflow: visible;
    width: 90px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#remark_lbl_staff_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 130px;
    overflow: visible;
}
#Remark_lbl_staff_page_bm {
    left: 35px;
    top: 132px;
    position: absolute;
    overflow: hidden;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Mohammed_Affan_lbl_staff_page {
    position: absolute;
    overflow: visible;
    top: 74px;
    display: flex;
    justify-content: center;
    left: 68px;
    width: 37px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#iphone-5_lbl_staff_page {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 220px;
    overflow: visible;
}
#staff_page_mobile_number_bp {
    left: 37px;
    top: 222px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Rectangle_37 {
    fill: rgba(119,238,170,1);
}
.Rectangle_37 {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 23px;
    left: 0px;
    top: 254px;
}
#staff_page_mobile_number_br {
    left: 8px;
    top: 256px;
    position: absolute;
    overflow: visible;
    width: 160px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(255,255,255,1);
}
#add_btn_staff_page {
    position: absolute;
    width: 97px;
    height: 36px;
    left: 172px;
    top: 36px;
    overflow: visible;
}
#add_bg_staff_page {
    fill: rgba(232,239,246,1);
}
.add_bg_staff_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 115px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#Icon_ionic-staff_page {
    fill: rgba(0,0,0,1);
}
.Icon_ionic-staff_page {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 9.5px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
}
#Active_lbl_staff_page {
    left: 34px;
    top: 9px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
