
.mediaViewInfo {
    --web-view-name: staff page mob;
    --web-view-id: staff_page_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: staff_page_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#staff_page_mob {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: staff page mob;
    --web-view-id: staff_page_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Scroll_staff_page_mob_card_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 90%;
    left: 0px;
    top: 62px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#staff_overall_card_mob{
    position: relative;
    overflow: visible;
    width: 93vw;
    height: 100px;
    left: 0px;
    top: 9px;
    margin-top: 7px;
}
#card_over_all_bg_staff_page_mo {
    fill: transparent;
}
.card_over_all_bg_staff_page_mo {
    position: relative;
    overflow: visible;
    width: 93vw;
    height: 100px;
    left: 12px;
    top: 9px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#card_bg_design_staff_page_mob {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 12px;
    top: 9px;
    overflow: visible;
}
/* #pfp_behind_circle_staff_page_m {
    fill: rgba(255,255,255,1);
} */
.pfp_behind_circle_staff_page_m {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 108px;
    height: 108px;
    left: 17.001px;
    top: 14px;
}
#profile_pic_card_staff_page_mo {
    position: absolute;
    width: 82px;
    height: 82px;
    left: 21.001px;
    top: 18px;
    overflow: hidden;
    border-radius: 50%;
}
#exp_staff_page_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 226px;
    top: 76px;
    overflow: visible;
}
#yr_staff_page_mob {
    left: 253px;
    top: 78px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#manager_lbl_staff_page_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 126px;
    top: 76px;
    overflow: visible;
}
#Admin_lbl_staff_page_mob {
    left: 153px;
    top: 78px;
    position: absolute;
    overflow: visible;
    width: 90px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#iphone-5_lbl_staff_page_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 226px;
    top: 47px;
    overflow: visible;
}
#n_234567890_mobile_number_staf {
    left: 253px;
    top: 49px;
    position: absolute;
    overflow: visible;
    width: 71px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#client_lbl_staff_page_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 126px;
    top: 45px;
    overflow: visible;
}
#client_lbl_staff_page_mob_c {
    left: 153px;
    top: 47px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Mohammed_Affan_lbl_staff_page_ {
    left: 126px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 226px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}