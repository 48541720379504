.mediaViewInfo {
    --web-view-name: timeline page  cards;
    --web-view-id: timeline_page__cards;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: timeline_page__cards;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#timeline_page__cards_timesheet {
    position: absolute;
    width: calc(100vw - 150px);
    height: calc(100vh - 170px);
    top: 155px;
    overflow-y: scroll;
    --web-view-name: timeline page  cards;
    --web-view-id: timeline_page__cards;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#timeline_page__cards_timesheet::-webkit-scrollbar{
    display: none;
}
#scroll_timelime::-webkit-scrollbar{
    display: none;
}
#scroll_timelime {
    mix-blend-mode: normal;
    position: absolute;
    width:calc(100% - 184px);
    height: auto;
    left: 0px;
    top: 0px;
    display: table;
    overflow-x: hidden;
    overflow-y: scroll;
}
#unassigned_status_drop_grp_timesheet {
    /* position: relative;
    width: calc(100% - 180px);
    display:block;
    height: auto; */
    /* left: 22px; */
    position: relative;
    width: 100%;
    height: auto;
    display: block;
    min-height: 20px;
    overflow: visible;

}
#top_status_area_timesheet {
    position: relative  ;
    width: calc(100vw - 180px);
    height: 18px;
    /* left: 0px; */
    top: 0px;
    right: 0px;
    overflow: visible;
}
#unassigned_lbl__timesheet {
    left: 42.5px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#right-arrow_2_drop_btn_timesheet {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 19.5px;
    top: 0px;
    overflow: visible;
    cursor: pointer;
}
#long_line_unassigned_timesheet {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.long_line_unassigned_timesheet {
    overflow: visible;
    position: absolute;
    width: 100%;
    height: 1px;
    left: 55px;
    top: 7.5px;
    transform: matrix(1,0,0,1,0,0);
}
#left_arrow_line__timesheet {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.left_arrow_line__timesheet {
    overflow: visible;
    position: absolute;
    width: 14px;
    height: 1px;
    left: 0px;
    top: 9.5px;
    transform: matrix(1,0,0,1,0,0);
}
