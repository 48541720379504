 .mediaViewInfo {
    --web-view-name: pup up add client;
    --web-view-id: pup_up_add_client;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pup_up_add_client;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pup_up_add_client {
    position: absolute;
    width: 442px;
    height: 1063px;
    left: 50%;
    transform: translate(-50%);
    /* top: 80%;
    left: 50%;
    transform: translate(-50%, -50%); */
    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: pup up add client;
    --web-view-id: pup_up_add_client;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_client_page_1 {
    fill: rgba(255,255,255,1);
}
.over_all_client_page_1 {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 379px;
    height: 825px;
    left: 39px;
    top: 28px;
}
#top_bg_sClient_page_1_ {
    fill: url(#top_bg_sClient_page_1_);
}
.top_bg_sClient_page_1_ {
    position: absolute;
    overflow: visible;
    width: 361px;
    height: 94px;
    left: 39px;
    top: 28px;
}
#Client_pfp_add_client_pfp {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 170px;
    top: 38px;
    overflow: visible;
}
/* #pfp_behind_circle_bg_Client_Co {
    fill: rgba(255,255,255,1);
} */
.pfp_behind_circle_bg_Client_Co {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 118px;
    height: 118px;
    left: 0px;
    top: 0px;
}
#Client_Company_Name__pfp_img {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 10px;
    top: 9.004px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow:   box-shadow;
    background:  #e0e0e0;
    border-radius: 50px;
}
#client_active_grp_ {
    position: absolute;
    width: 97.572px;
    height: 27px;
    left: 171px;
    top: 146.004px;
    overflow: visible;
}
#active_lbl_client {
    left: 43.572px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
 #active_chkbx {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.active_chkbx {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 20px;
    left: 0px;
    top: 4px;
} 
#add_client__btn_grp:hover {
    left: 164px;
    top: 864px;
}
#add_client__btn_grp {
    position: absolute;
    width: 123px;
    height: 41px;
    left: 162px;
    top: 860px;
    overflow: visible;
    border-radius: 20px;
    transition: all .3s ease-out;
}
#addd_bdr_btn_client_ {
    fill: rgba(192,255,219,1);
}
.addd_bdr_btn_client_ {
    position: absolute;
    overflow: visible;
    width: 123px;
    height: 41px;
    left: 0px;
    top: 0px;
}
#Add_pup_opClient__lbl{
    position: absolute;
    left: 43.914px;
    top: 3.369px;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#close_pup_op_client {
    position: absolute;
    cursor: pointer;
    width: 25px;
    height: 25px;
    left: 365px;
    top: 38px;
    overflow: visible;
}
#scroll_add_client {
    mix-blend-mode: normal;
    position: absolute;
    width: 361.8px;
    height: 657px;
    left: 39px;
    top: 180.504px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Client_Company_Name_name_grp_p {
    position: absolute;
    width: 321.329px;
    height: 62px;
    left: 20px;
    top: 6px;
    overflow: visible;
}
#Client_Company_Name_name__lbl_ {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Client_Company_Name_name__inpu {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Client_Company_Name_name__inpu {
    position: absolute;
    overflow: visible;
    width: 321.329px;
    height: 40px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#client_Company_Information_nam {
    position: absolute;
    width: 321.329px;
    height: 63px;
    left: 20px;
    top: 77.991px;
    overflow: visible;
}
#client_Company_Information_nam_br {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 165px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#client_Company_Information_nam_bs {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.client_Company_Information_nam_bs {
    position: absolute;
    overflow: visible;
    width: 321.329px;
    height: 70px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#client_gst_no_grp {
    position: absolute;
    width: 321.579px;
    height: 63px;
    left: 20px;
    top: 180.991px;
    overflow: visible;
}
#gst_no_CPN_skill_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#gst_no_skill_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.gst_no_skill_input_pfp {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#client_address_exp_grp {
    position: absolute;
    width: 321.8px;
    height: 93px;
    left: 20px;
    top: 254px;
    overflow: visible;
}
#client_address_exp_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#client_addres__exp_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.client_addres__exp_input_pfp {
    position: absolute;
    overflow: visible;
    width: 321.8px;
    height: 70px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Contact_Person_Designation_pro {
    position: absolute;
    width: 321.579px;
    height: 63px;
    left: 20px;
    top: 649px;
    overflow: visible;
}
#client_pup_opContact_Person_D {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 209px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#client__pup_op_Contact_Person {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.client__pup_op_Contact_Person {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Contact_Person_Mobile_client {
    position: absolute;
    width: 321.579px;
    height: 63px;
    left: 20px;
    top: 576px;
    overflow: visible;
}
#Contact_Person_Mobile_pup_op_c{
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 171px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Contact_Person_Mobile_pup_oppr {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Contact_Person_Mobile_pup_oppr {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#CPM_client_grp {
    position: absolute;
    width: 321.579px;
    height: 63px;
    left: 20px;
    top: 503px;
    overflow: visible;
}
#remark_pup_op_client__lbl {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 164px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#remark_pup_opclient__input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.remark_pup_opclient__input {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Accounts_Person_Designation_pr_client {
    position: absolute;
    width: 321.579px;
    height: 63px;
    left: 20px;
    top: 792px;
    overflow: visible;
}
#Accounts_Person_Name_pr_client {
    position: absolute;
    width: 321.579px;
    height: 43px;
    left: 20px;
    top: 722px;
    overflow: visible;
}
#client_pup_op_Accounts_Person {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 220px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#client_pup_op_Accounts_Person_Name {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 220px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#project__pup_opAccounts_Person_Name {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.project__pup_opAccounts_Person_Name {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#project__pup_opAccounts_Person {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.project__pup_opAccounts_Person {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#client_gst_no_grp_cb {
    position: absolute;
    width: 146px;
    height: 63px;
    left: 20px;
    top: 357px;
    overflow: visible;
}
#gst_no_CPN_skill_lbl_pfp_cc {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#gst_no_skill_input_pfp_cd {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.gst_no_skill_input_pfp_cd {
    position: absolute;
    overflow: visible;
    width: 146px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#client_gst_no_grp_ce {
    position: absolute;
    width: 146px;
    height: 63px;
    left: 195px;
    top: 357px;
    overflow: visible;
}
#gst_no_CPN_skill_lbl_pfp_cf {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 39px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#gst_no_skill_input_pfp_cg {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.gst_no_skill_input_pfp_cg {
    position: absolute;
    overflow: visible;
    width: 146px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#client_gst_no_grp_ch {
    position: absolute;
    width: 146px;
    height: 63px;
    left: 20px;
    top: 430px;
    overflow: visible;
}
#gst_no_CPN_skill_lbl_pfp_ci {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 85px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#gst_no_skill_input_pfp_cj {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.gst_no_skill_input_pfp_cj {
    position: absolute;
    overflow: visible;
    width: 146px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#client_gst_no_grp_ck {
    position: absolute;
    width: 146px;
    height: 63px;
    left: 195px;
    top: 430px;
    overflow: visible;
}
#gst_no_CPN_skill_lbl_pfp_cl {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 28px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#gst_no_skill_input_pfp_cm {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.gst_no_skill_input_pfp_cm {
    position: absolute;
    overflow: visible;
    width: 146px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Accounts_Person_Mobile_client {
    position: absolute;
    width: 321.579px;
    height: 63px;
    left: 20px;
    top: 865px;
    overflow: visible;
}
#Accounts_Person_Mobile_pup_op_client {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 182px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Accounts_Person_Mobile__pup_op {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Accounts_Person_Mobile__pup_op {
    position: absolute;
    overflow: visible;
    width: 321.579px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
.switch_client{
    left: -26px;
    top: 3px;
} 


/* b78t7c3trbwssghnhdgh */

