#proj_assignment_grp {
    position: fixed;
    /* top: 0px; */
    display: block;
    width: 240px;
    height: 55px;
   right: 40px;
    bottom: 50px;
    background-color: rgba(219, 242, 231, 1);
    border-radius: 50px;
    z-index: 10;
    border: 5px solid rgba(219, 242, 231, 1);
    /* overflow: visible; */
}
#proj_assignment_grp:hover{
opacity: 0;

}
#Project_Assignments_lbl {
    left: 57px;
    top: 13px;
    position: absolute;
    /* overflow: visible; */
    width: 169px;
    white-space: nowrap;
    text-align: center;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
    /* background-color: white; */
}
#Project_Assignments_lbl:hover {
    display: none;
    z-index: -1;

}
#logo_Project_Assignments {
    /* position: absolute; */
    width: 45px;
    height: 45px;
    border-radius: 50%;
    left: 0px;
    top: 0px;
    overflow: hidden;
    /* background-color: white; */
}
#logo_Project_Assignments:hover {
    display: none;
    z-index: -1;
}
#logo_container{
    width: 45px;
    height: 45px;
    border-radius: 50%;

}
#logo_container:hover{
   display: none;
   z-index: -1;

}