#heading_and_card_1_time_sheet_ {
    position: relative;
    width: 1000px;
    height: 390px;
    margin: 10px;
    /* left: 20px; */
    top: 161px;
    overflow: visible;
}
#detIAIL_HEADING_timeline_page {
    position: absolute;
    width: 1000px;
    height: 41px;
    left: 15px;
    top: 0px;
    overflow: visible;
}
#header_1_timeline_page {
    fill: rgba(255,255,255,1);
}
.header_1_timeline_page {
    filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 1165px;
    height: 49px;
    left: 0px;
    top: 0px;
}
#module_lbl_timeline_page {
    left: 343px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#hours_lbl_timeline_page {
    left: 775px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 43px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#staff_lbl_timeline_page {
    left: 1050px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 35px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#project_lbl_timeline_page {
    left: 111px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#date_timeline_page {
    left: 9px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 34px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#status_lbl_timeline_page {
    left: 910px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#category_timeline_page {
    left: 495px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#task_lbl_timeline_page {
    left: 654px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 32px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Scroll_timesheet_page_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 120%;
    height: 361px;
    left: 0px;
    top: 63px;
    overflow-x: hidden;
    overflow-y: scroll;

}
#Scroll_timesheet_page_scroll::-webkit-scrollbar{
    display: none;
}
#card_1_timeline_page {
    position: relative;
    margin: 10px;
    width: 1159px;
    height: 41px;
    left: 6px;
    top: -2px;
    overflow: hidden;
}
#card_1_bg_timeline_page {
    fill: rgba(255,255,255,1);
}
.card_1_bg_timeline_page {
    filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 1165px;
    height: 49px;
    left: 0px;
    top: 0px;
}
#md_affan_timeline_page {
    left: 112px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 164px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#module_timeline_page {
    left: 345px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#hours_timeline_page {
    left: 778px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 17px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#date_timeline_page_dn {
    left: 9px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 79px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
/* #status_color {
    fill: rgba(243,91,91,1);
} */
.status_color {
    position: absolute;
    overflow: visible;
    width: 122px;
    height: 41px;
    left: 872px;
    top: 0px;
}
#status_timeline_page {
    left: 872px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 123px;
    height: 20px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#task_date_timeline_page {
    left: 655px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#documantation_timeline_page {
    left: 495px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#doc_lbl_timeline_page {
    left: 1047px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
